import { useCallback, useState, useMemo } from "react"
import {
    Input, Table, DatePicker, Tooltip, Button, Modal, Dropdown
} from "antd"
import {
    SearchOutlined, DownloadOutlined,
    SyncOutlined, DownOutlined, CheckOutlined
} from "@ant-design/icons"
//import ipaddr from "ipaddr.js"
import dayjs from "dayjs"

import CountrySelector from "components/selector/CountrySelect"
import DomainExpandable from "components/DomainExpandable"

//import domainNameRegex from "lib/domainNameRegex"
//import emailRegex from "lib/emailRegex"
import useQuery from "hooks/useQuery"
import { loadRegistrarLog, columns } from "api/registrarLog"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import { downloadCsv } from "lib/csv"
//import useQueryOptions from "hooks/useQueryOptions"
import useDualState from "hooks/useDualState"
import useUpdateEffect from "hooks/useUpdateEffect"
import async from "lib/async"
import renderGMT from "lib/renderGMT"
import findParent from "lib/dom/findParent"
import supabase from "lib/supabase/main"
import { Portal } from "components/Portal"

// const defaultQueryOptions = [
//     { value: 'registrar', label: 'Registrar' },
//     { value: 'domain', label: 'Domain' },
//     { value: 'registrantName', label: 'Registrant name' },
//     { value: 'registrantEmail', label: 'Registrant email' },
//     { value: 'customerName', label: 'Customer name' },
//     { value: 'customerEmail', label: 'Customer email' },
//     { value: 'ip', label: 'Registration IP' },
//     { value: 'cidr', label: 'Registration IP CIDR' },
// ];

const params = [
    {
        name: "country",
        default: null,
        autoApply: true
    },
    {
        name: "source",
        default: ""
    },
    {
        name: "query",
        default: "",
        serialize: v => v ? v.trim() : undefined
    },
    {
        name: "queryBy",
        default: null
    },
    {
        name: "status",
        //serialize: v => v ? 'true' : 'false',
        //unserialize: v => v === 'true' ? true : false,
        default: "",
        autoApply: true
    },
    {
        name: "withHistory",
        default: "",
        autoApply: true,
        serialize: v => v ? "true" : undefined,
        unserialize: v => v === "true"
    },
    {
        name: "dateRange",
        default: [],
        serialize: dr => dr ? dr.map(d => d.toISOString()).join(",") : undefined,
        unserialize: dr => dr ? dr.split(",").map(d => dayjs(d)) : []
    }
];

const PER_PAGE = 20;
const defaultInitialParams = {};
const defaultEmptyColumns = [];


function DomainDateModal({ open, onClose, onSubmit }) {

    const [date, setDate] = useState(null)

    const onOk = useCallback(
        () => {
            setDate(null);
            return onSubmit(date);
        },
        [onSubmit, date]
    );

    return (
        <Modal
            title="Set registration date"
            centered
            open={open}
            destroyOnClose
            onCancel={onClose}
            onOk={onOk}>
            <DatePicker
                value={date}
                style={{ width: "100%" }}
                showTime
                onChange={setDate} />
        </Modal>
    )
}


function RegistrarLog({
    toolbarPortal = null,
    before = null,
    toolbarSize = "default",
    stateMode = "state",
    showToolbar = true,
    allowDateRange = true,
    allowSearch = true,
    allowCsv = true,
    allowMore = true,
    domain = null,
    //excludeQueryOptions,
    initialParams = defaultInitialParams }) {

    const [showDateModal, setShowDateModal] = useState(false);
    const [dateForDomainId, setDateForDomainId] = useState(null);
    const [page, setPage] = useState(0);
    //const [ pageSize, setPageSize ] = useState(PER_PAGE)

    const {
        //active, setActive,
        status, setStatus,
        query, setQuery,
        dateRange, setDateRange,
        queryBy, /*setQueryBy,*/
        country, setCountry,
        source, setSource,
        withHistory, setWithHistory,
        all, apply
    } = useDualState({ params, mode: stateMode, defaults: initialParams });

    const [downloading, setDownloading] = useState(false);
    //const queryOptions = useQueryOptions(defaultQueryOptions, excludeQueryOptions);

    const { data, isLoading, reset, refetch, extraData = {} } = useQuery(
        loadRegistrarLog,
        [page, all, domain],
        {
            rowIdKey: "domain",
            params: { ...initialParams, domain, page, pageSize: PER_PAGE, ...all },
            initialData: [],
            append: true,
            replica: true
        },
        [all, domain]
    );
    const { hasMore = false } = extraData;
    const fetchedColumns = extraData.columns || defaultEmptyColumns;

    const onEditDateClick = useCallback(
        (e) => {
            const btn = findParent(e.target, "button");
            const domain = btn.dataset.domain;
            setShowDateModal(true);
            setDateForDomainId(domain);
        },
        []
    );

    const tableColumns = useMemo(
        () => {
            let tableColumns = columns
                .filter(c => fetchedColumns.indexOf(c.dataIndex) !== -1)
                .filter(c => {
                    if (!c.status) {
                        return true;
                    }
                    if (c.status[status] !== undefined) {
                        return c.status[status];
                    }
                    if (c.status["_"] !== undefined) {
                        return c.status["_"];
                    }
                    return true;
                });

            const creationCol = tableColumns.find(c => c.dataIndex === "creation_date");

            if (creationCol) {
                creationCol.render = function (value, row) {
                    if (value) {
                        return renderGMT(value);
                    }
                    else if (row.status === "Active") {
                        return (
                            <Button
                                data-domain={row.domain}
                                onClick={onEditDateClick}
                                size="small"
                                children="Set date" />
                        )
                    }
                    return null;
                }
            }

            return tableColumns;
        },
        [fetchedColumns, onEditDateClick, status]
    );


    useUpdateEffect(
        () => reset(),
        [all, reset]
    );

    const onQueryChange = useCallback(
        (e) => {
            //const query = e.target.value.trim();

            /*if (query) {
                if (ipaddr.isValid(query)) {
                    setQueryBy("ip");
                }
                else if (query.indexOf("/") !== -1 && ipaddr.isValid(query.split("/")[0])) {
                    setQueryBy("cidr");
                }
                else if (query.match(domainNameRegex)) {
                    setQueryBy("domain");
                }
                else if (query.match(emailRegex)) {
                    if (queryBy !== "registrantEmail" && queryBy !== "customerEmail") {
                        setQueryBy("registrantEmail");
                    }
                }
                else {
                    if (queryBy !== "registrar" && queryBy !== "registrantName" && queryBy !== "customerName") {
                        setQueryBy("registrar");
                    }
                }
            }
            else {
                setQueryBy(undefined);
            }*/

            setQuery(e.target.value);
        },
        [ /*queryBy, setQueryBy,*/ setQuery]
    );

    const loadNextPage = useCallback(
        () => {
            setPage(prev => prev + 1);
        },
        [setPage]
    );

    const onSearchClick = useCallback(
        () => {
            reset()
            setPage(0);
            async(apply);
        },
        [setPage, apply, reset]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSearchClick();
            }
        },
        [onSearchClick]
    );

    const onDownloadCsv = useSwallowEventCallback(
        async () => {
            setDownloading(true);
            const csv = await loadRegistrarLog({
                query,
                queryBy,
                dateRange,
                withHistory,
                page,
                csv: true,
                status
            });
            downloadCsv(csv, "registrar_log.csv");
            setDownloading(false);
        },
        [page, query, queryBy, dateRange, status, withHistory]
    );

    const onDateModalClose = useCallback(
        () => {
            setShowDateModal(false);
            setDateForDomainId(null);
        },
        []
    );

    const onDateModalSubmit = useCallback(
        async (date) => {
            if (date) {
                await supabase
                    .from("domain")
                    .update({ creation_date: date.toISOString() })
                    .eq("domain", dateForDomainId);
                refetch();
            }

            setShowDateModal(false);
            setDateForDomainId(null);
        },
        [dateForDomainId, refetch]
    );


    const renderExpanded = useCallback(
        (row) => {

            return (
                <div style={{ padding: 10, paddingTop: 0 }}>
                    <DomainExpandable
                        domain={row.domain}
                        initialRegistrarData={row} />
                </div>
            )

            /*return (
                <>
                <Registrant registrant={ row.registrant }/>
                <br/>
                <Customer customer={ row.customer }/>
                </>
            )*/
        },
        []
    );

    const expandable = useMemo(
        () => {
            return {
                expandedRowRender: renderExpanded,
                rowExpandable: () => true,
            };
        },
        [renderExpanded]
    );

    const onSelectOption = useCallback(
        ({ key }) => {
            const [type, value] = key.split("/");
            if (type === "status") {
                setStatus(value);
            }
            else if (type === "source") {
                setSource(value);
            }
            else if (type === "history") {
                setWithHistory(prev => !prev);
            }
        },
        [setStatus, setSource, setWithHistory]
    );

    const optionsMenu = useMemo(
        () => {

            const searchOptions = [
                {
                    key: "status/",
                    label: "Any status",
                    icon: status === "" ? <CheckOutlined /> : null
                },
                {
                    key: "status/active",
                    label: "Active",
                    icon: status === "active" ? <CheckOutlined /> : null
                },
                {
                    key: "status/inactive",
                    label: "Inactive",
                    icon: status === "inactive" ? <CheckOutlined /> : null
                },
                {
                    key: "status/expiring",
                    label: "Expiring (yet active)",
                    icon: status === "expiring" ? <CheckOutlined /> : null
                },
                {
                    key: "status/expired",
                    label: "Expired (yet active)",
                    icon: status === "expired" ? <CheckOutlined /> : null
                }
            ];

            const sourceOptions = [
                {
                    key: "source/",
                    label: "All sources",
                    icon: source === "" ? <CheckOutlined /> : null
                },
                {
                    key: "source/endurance",
                    label: "Endurance",
                    icon: source === "endurance" ? <CheckOutlined /> : null
                },
                {
                    key: "source/namesilo",
                    label: "Namesilo",
                    icon: source === "namesilo" ? <CheckOutlined /> : null
                }
            ]

            const options = [
                {
                    value: null,
                    label: "Status",
                    type: "group",
                    children: searchOptions
                },
                {
                    value: null,
                    label: "Source",
                    type: "group",
                    children: sourceOptions
                },
                {
                    value: null,
                    label: "History",
                    type: "group",
                    children: [
                        {
                            key: "history/withHistory",
                            label: "With history",
                            icon: withHistory === true ? <CheckOutlined /> : null
                        },
                    ]
                }
            ];

            return {
                items: options,
                size: toolbarSize,
                multiple: true,
                onClick: onSelectOption,
                selectedKeys: [
                    `status/${status}`,
                    `source/${source}`,
                    withHistory ? "history/withHistory" : null
                ].filter(Boolean)
            }

        },
        [toolbarSize, status, source, withHistory, onSelectOption]
    );

    return (
        <div className="table-dns-log">
            {showToolbar &&
                <Portal host={toolbarPortal}>
                    {before}
                    <div className="toolbar">
                        {allowDateRange &&
                            <DatePicker.RangePicker
                                placement="bottomLeft"
                                showTime
                                size={toolbarSize}
                                value={dateRange}
                                onChange={setDateRange} />}
                        {allowSearch &&
                            <>
                                <CountrySelector
                                    placeholder="Country"
                                    allowClear
                                    showSearch
                                    size={toolbarSize}
                                    value={country}
                                    onChange={setCountry} />
                                <Dropdown
                                    trigger="click"
                                    menu={optionsMenu}>
                                    <Button
                                        size={toolbarSize}
                                        children="Options"
                                        icon={<DownOutlined />}
                                        iconPosition="end" />
                                </Dropdown>
                                <Input
                                    allowClear
                                    size={toolbarSize}
                                    onKeyDown={onKeyDown}
                                    prefix={<SearchOutlined />}
                                    onChange={onQueryChange}
                                    value={query} />
                            </>}
                        {(allowDateRange || allowSearch) &&
                            <Button
                                size={toolbarSize}
                                loading={isLoading}
                                disabled={isLoading}
                                onClick={onSearchClick}
                                children="Search" />}
                        {allowCsv &&
                            <Tooltip title="Export as CSV">
                                <Button
                                    type="text"
                                    size={toolbarSize}
                                    onClick={onDownloadCsv}
                                    icon={downloading ? <SyncOutlined spin /> : <DownloadOutlined />} />
                            </Tooltip>}
                    </div>
                </Portal>}
            <Table
                sticky
                size="small"
                bordered
                loading={isLoading}
                dataSource={data}
                columns={tableColumns}
                rowKey="domain"
                expandable={expandable}
                pagination={false} />
            {(allowMore && hasMore) &&
                <>
                    <br />
                    <Button
                        loading={data.length > 0 && isLoading}
                        onClick={loadNextPage}
                        children="Load more"
                        disabled={isLoading}
                        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                </>}
            <DomainDateModal
                open={showDateModal}
                onClose={onDateModalClose}
                onSubmit={onDateModalSubmit} />
        </div>
    )
}

export default RegistrarLog