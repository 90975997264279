import {
    Descriptions, Spin, Tag, Typography,
    Table, Button, Flex, Modal, Input
} from "antd";
import { useLocation } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";

import Page from "components/Page";
import { Portal } from "components/Portal";
import ThreatByDate from "components/report/TheatByDate";
import DnsLog from "components/table/DnsLog";
import TarpitLog from "components/table/TarpitLog";
import TopCountries from "components/report/TopCountries";
import TopVictims from "components/report/TopVictims";
import LayoutWithMenu from "components/LayoutWithMenu";

import supabase from "lib/supabase/main";
import useQuery from "hooks/useQuery";
import ThreatName from "components/ThreatName";
import HuntReportLog from "components/table/HuntReportLog";
import RegistrarLog from "components/table/RegistrarLog";
import useUpdateEffect from "hooks/useUpdateEffect";
import useAppKey from "hooks/useAppKey";


async function loadMalwareInfo({ malwareId }) {
    return await supabase.from("threat")
        .select("*")
        .eq("id", malwareId);
}


const menu = [
    {
        key: "info",
        label: "Info"
    },
    {
        key: "hunted-domains",
        label: "Hunted domains"
    },
    {
        key: "associated-domains",
        label: "Associated domains"
    },
    {
        key: "top-countries",
        label: "Top countries"
    },
    {
        key: "top-victims",
        label: "Top victims"
    },
    {
        key: "dns-log",
        label: "DNS log"
    },
    {
        key: "tarpit-log",
        label: "Tarpit log"
    }
];

const pages = {
    "info": {
        load: async ({ malware, setData, toggleLoading }) => {
            toggleLoading("info", true);
            const tags = [];

            if (malware) {
                if (malware.type) {
                    tags.push({
                        key: "type",
                        label: "Type",
                        children: [
                            <Tag key="type">{malware.type}</Tag>
                        ]
                    })
                }

                if (malware.malpedia_data?.alt_names &&
                    malware.malpedia_data.alt_names.length > 0) {
                    tags.push({
                        key: "alt_names",
                        label: "Alt Names",
                        children: malware.malpedia_data.alt_names.map(name => (
                            <Tag key={name}>{name}</Tag>
                        ))
                    });
                }

                if (malware.malpedia_data?.attribution &&
                    malware.malpedia_data.attribution.length > 0) {
                    tags.push({
                        key: "attribution",
                        label: "Attribution",
                        children: malware.malpedia_data.attribution.map(attribution => (
                            <Tag key={attribution}>{attribution}</Tag>
                        ))
                    });
                }

                if (malware.malpedia_data?.urls &&
                    malware.malpedia_data.urls.length > 0) {
                    const rows = malware.malpedia_data.urls.map(url => ({ url }));
                    const columns = [
                        {
                            title: "URL",
                            dataIndex: "url",
                            key: "url",
                            render: (url) => (
                                <a key={url} href={url} target="_blank" rel="noreferrer">{url}</a>
                            )
                        }
                    ]
                    tags.push({
                        key: "urls",
                        label: "URLs",
                        children: (
                            <Table
                                style={{ width: "100%" }}
                                bordered={false}
                                size="small"
                                dataSource={rows}
                                columns={columns} />
                        )
                    });
                }

                if (malware.yara_available) {
                    const url = `https://malpedia.caad.fkie.fraunhofer.de/yara/${malware.id}`;
                    tags.push({
                        key: "yara",
                        label: "YARA rules",
                        children: [
                            <a href={url} target="_blank" rel="noreferrer">Download YARA rules</a>
                        ]
                    });
                }
            }

            setData(prev => {
                if (tags.length === 0) {
                    return prev;
                }
                return { ...prev, "info": tags }
            });
            toggleLoading("info", false);
        },
        render: ({ data, loading }) => {
            if (loading) {
                return <Spin spinning />
            }
            return (
                <Descriptions
                    bordered
                    className="page-malware-tags"
                    column={1}
                    items={data} />
            )
        }
    },
    "hunted-domains": {
        title: "Hunted domains",
        render: ({ malwareId }) => {
            return (
                <HuntReportLog
                    showToolbar={false}
                    initialParams={{ threatId: malwareId }} />
            )
        }
    },
    "associated-domains": {
        title: "Associated domains",
        load: async ({ malwareId, setData, toggleLoading }) => {
            toggleLoading("associated-domains", true);

            const [{ data: queue }, { data: related }, { data: hunted }] = await Promise.all([
                supabase.from("hunt_report_queue").select().eq("threat_id", malwareId),
                supabase.from("registrar_related_hunt").select().eq("threat_id", malwareId),
                supabase.from("hunt_report_log").select().eq("threat_id", malwareId)
            ]);
            // const { data: queue } = await queueResp;
            const huntedDomains = hunted.map(row => row.domain);
            const domains = [];

            queue.forEach(row => {
                if (!domains.includes(row.domain) && !huntedDomains.includes(row.domain)) {
                    domains.push(row.domain);
                }
            });
            related.forEach(row => {
                if (!domains.includes(row.domain) && !huntedDomains.includes(row.domain)) {
                    domains.push(row.domain);
                }
            });

            setData(prev => ({ ...prev, "associated-domains": domains }));
            toggleLoading("associated-domains", false);
        },
        render: ({ data, loading }) => {
            if (data && data.length > 0) {
                return (
                    <Spin spinning={loading}>
                        <RegistrarLog showToolbar={false} domain={data} />
                    </Spin>
                )
            }
        }
    },
    "top-countries": {
        render: ({ malwareId }) => {
            return (
                <TopCountries
                    title="Top countries"
                    titleLevel={2}
                    bordered
                    params={{
                        threatId: malwareId,
                        period: "last7"
                    }} />
            )
        }
    },
    "top-victims": {
        render: ({ malwareId }) => {
            return (
                <TopVictims
                    title="Top victims"
                    titleLevel={2}
                    bordered
                    params={{
                        threatId: malwareId,
                    }} />
            )
        }
    },
    "dns-log": {
        title: "DNS log",
        render: ({ malwareId }) => {
            return (
                <DnsLog
                    initialParams={{
                        threatId: malwareId,
                        // withThreat: true
                    }} />
            )
        }
    },
    "tarpit-log": {
        title: "Tarpit log",
        render: ({ malwareId }) => {
            return (
                <TarpitLog
                    initialParams={{
                        threatId: malwareId,
                        // withThreat: true
                    }} />
            )
        }
    }
};


function MalwareNotesDialog({ show, notes, onSubmit, onCancel }) {

    const [value, setValue] = useState(notes);
    const [submitting, setSubmitting] = useState(false);

    const onValueChange = useCallback(
        (e) => setValue(e.target.value),
        []
    );

    const onSubmitClick = useCallback(
        async () => {
            setSubmitting(true);
            await onSubmit(value);
            setSubmitting(false);
        },
        [onSubmit, value]
    );

    useUpdateEffect(
        () => setValue(notes),
        [notes]
    );

    return (
        <Modal
            title="Edit notes"
            open={show}
            okButtonProps={{ loading: submitting, disabled: submitting }}
            cancelButtonProps={{ disabled: submitting }}
            onOk={onSubmitClick}
            onCancel={onCancel}>
            <Input.TextArea
                disabled={submitting}
                value={value}
                style={{ height: "10rem" }}
                onChange={onValueChange} />
        </Modal>
    )
}


function MalwarePage() {

    const { pathname } = useLocation();
    const malwareId = pathname.split("/").pop();
    const [showNotesDialog, setShowNotesDialog] = useState(false);
    const isAdmin = useAppKey("isAdmin");

    const { data: malware, isLoading, refetch } = useQuery(
        loadMalwareInfo,
        [malwareId],
        {
            params: {
                malwareId
            },
            initialData: null,
            prepare: (data) => data[0],
        }
    );

    const threatByDateParams = useMemo(
        () => ({
            threatId: malwareId
        }),
        [malwareId]
    );

    const onEditNotesClick = useCallback(
        () => {
            setShowNotesDialog(true);
        },
        []
    );

    const onNotesCancel = useCallback(
        () => setShowNotesDialog(false),
        []
    );

    const onNotesSubmit = useCallback(
        async (value) => {
            await supabase.from("threat")
                .update({ notes: value })
                .eq("id", malwareId);
            setShowNotesDialog(false);
            refetch();
        },
        [malwareId, refetch]
    );

    return (
        <Page className="page-malware">
            <Portal host="header">
                <Spin spinning={isLoading}>
                    <Flex justify="space-between" align="center">
                        <Typography.Title level={1}>
                            <ThreatName
                                withLink={false}
                                id={malwareId}
                                name={malware?.name} />
                        </Typography.Title>
                        {isAdmin &&
                            <Button type="default" onClick={onEditNotesClick}>
                                Edit notes
                            </Button>}
                    </Flex>
                    {malware?.description && (
                        <Typography.Paragraph>
                            {malware?.description}
                        </Typography.Paragraph>
                    )}
                    {malware?.notes && (
                        <Typography.Paragraph>
                            {malware?.notes}
                        </Typography.Paragraph>
                    )}
                    <ThreatByDate
                        params={threatByDateParams}
                        showToolbar={false}
                        chartType="bar" />
                </Spin>
            </Portal>

            <LayoutWithMenu
                menu={menu}
                pages={pages}
                defaultSelectedKeys={["info"]}
                defaultOpenKeys={["info"]}
                pageLoadOptions={{ malwareId, malware }}
                pageRenderOptions={{ malwareId, malware }} />

            {isAdmin && <MalwareNotesDialog
                show={showNotesDialog}
                notes={malware?.notes}
                onCancel={onNotesCancel}
                onSubmit={onNotesSubmit} />}

        </Page>
    )
}

export default MalwarePage;