import { Link } from "react-router-dom"
import supabase from "lib/supabase/main"
import renderGMT from "lib/renderGMT"
import { data2csv } from "lib/csv"
import IpLink from "components/link/Ip"
import aws from "lib/aws"
import CountryName from "components/CountryName"

export const columns = [
    {
        dataIndex: "domain",
        key: "domain",
        title: "Domain",
        render: domain => <Link to={`/reports/domain/${domain}`} children={domain} />
    },
    {
        dataIndex: "registrar",
        key: "registrar",
        title: "Registrar"
    },
    {
        dataIndex: "status",
        key: "status",
        title: "Status"
    },
    {
        dataIndex: "registration_ip",
        key: "registration_ip",
        title: "Registration IP",
        render: ip => <IpLink ip={ip} />
    },
    {
        dataIndex: "creation_date",
        key: "creation_date",
        title: "Creation date (GMT)",
        render: v => renderGMT(v),
        status: { "expiring": false, "expired": false, "_": true }
    },
    {
        dataIndex: "expiration_date",
        key: "expiration_date",
        title: "Expiry date (GMT)",
        render: v => renderGMT(v),
        status: { "expiring": true, "expired": true, "_": false }
    },
    /*{
        dataIndex: "registrant",
        key: "registrant",
        title: "Registrant",
        render: (r) => r.name
    },
    {
        dataIndex: "customer",
        key: "customer",
        title: "Customer",
        render: (r) => r.name
    },*/
    {
        dataIndex: "country",
        key: "country",
        title: "Country",
        status: { "expiring": false },
        render: (code) => {
            if (!code) {
                return "";
            }
            return (<CountryName country={code} />);
            //return countries.find(c => c.code === code)?.name || code;
        }
    },
    {
        dataIndex: "source",
        key: "source",
        title: "Source"
    },
];

export async function loadRegistrarLog(options = {}) {

    const {
        //queryBy = null,
        query,
        domain,
        dateRange,
        page = 0,
        pageSize = 20,
        csv = false,
        single = false,
        //active = true,
        status = null,
        replica = true,
        withHistory = null,
        //withCount,
        country,
        source,
        report,
        period
    } = options;

    const params = {
        offset: csv ? 0 : page * pageSize,
        limit: csv ? 1000 : pageSize,//((page + 1) * pageSize),
        // queryBy,
        // query, 
        period,
        country,
        domain,
        source,
        withHistory: typeof withHistory === "boolean" ? withHistory : null,
        active: status === "active" ? true :
            status === "inactive" ? false : null,
        expiring: status === "expiring" ? true : null,
        expired: status === "expired" ? true : null,
        //withTraffic: status === "expiring" || status === "expired" ? true : null,
        orderBy: status === "expired" || status === "expiring" ?
            "expiration_date" :
            "creation_date",
        //orderDir: status === "expiring" ? "asc" : "desc",
        withCount: false, //withCount !== undefined ? withCount : page === 0,
        dateRange: dateRange ? dateRange.map(d => typeof d === "string" ? d : d.toISOString()) : null
    };

    if (query) {
        const resp = await aws.backend.post("/search", {
            body: {
                type: "domain",
                query,
                start: csv ? 0 : page * pageSize,
                limit: csv ? 1000 : pageSize
            }
        });
        if (!resp || !resp.results || resp.results.length === 0) {
            return { data: [], columns: [], count: 0 };
        }
        params.domain = resp.results;
        // params.limit = 1000;
        params.offset = 0;
        params.limit = 1000;
    }

    const { data: { rows, columns, count }, error } = await supabase.functions.invoke("query", {
        body: {
            table: "domain",
            report,
            options: params,
            replica
        },
        method: "POST"
    });

    const hasMore = rows.length >= pageSize;

    if (csv) {
        const csvColumns = columns.filter(c =>
            c !== "id" && c !== "registrant" && c !== "customer" &&
            c !== "reseller" && c !== "misc");
        if (rows.length > 0) {
            const registrant = rows[0].registrant;
            const customer = rows[0].customer;
            Object.keys(registrant).forEach(key => {
                csvColumns.push("registrant_" + key);
            });
            Object.keys(customer).forEach(key => {
                csvColumns.push("customer_" + key);
            });
            rows.forEach(row => {
                Object.keys(row.registrant).forEach(key => {
                    row["registrant_" + key] = row.registrant[key];
                })
                Object.keys(row.customer).forEach(key => {
                    row["customer_" + key] = row.customer[key];
                })
            });
        }
        const text = data2csv(csvColumns, rows);
        return text;
    }

    if (single) {
        return rows[0];
    }
    return { data: rows, columns, count, error, hasMore };
}