

function getDNSKeyAlgorithm(num) {
    const algorithms = {
        '1': 'RSA/MD5',
        '2': 'Diffie-Hellman',
        '3': 'DSA/SHA1',
        '5': 'RSA/SHA-1',
        '6': 'DSA-NSEC3-SHA1',
        '7': 'RSASHA1-NSEC3-SHA1',
        '8': 'RSA/SHA-256',
        '10': 'RSA/SHA-512',
        '12': 'GOST R 34.10-2001',
        '13': 'ECDSA Curve P-256 with SHA-256',
        '14': 'ECDSA Curve P-384 with SHA-384',
        '15': 'Ed25519',
        '16': 'Ed448'
    };
    return algorithms[num] || 'Unknown Algorithm';
}


const interpretations = {
    'A': (answer) => `IP address: ${answer}`,
    'AAAA': (answer) => `IPv6 address: ${answer}`,
    'AFSDB': (answer) => {
        const [subtype, hostname] = answer.split(' ');
        return `AFS Database record:\n` +
            `Subtype: ${subtype} (${subtype === '1' ? 'AFS cell database server' : 'DCE/NCA root cell directory node'})\n` +
            `Hostname: ${hostname}`;
    },
    'APL': (answer) => {
        const parts = answer.split(' ');
        return `Address Prefix List: ${parts.join(', ')}`;
    },
    'CAA': (answer) => {
        const [, tag, value] = answer.split(' ');
        return `Certificate Authority Authorization:\n` +
            // `Flags: ${flags}\n` +
            `Tag: ${tag}\n` +
            `Value: ${value}`;
    },
    'CDNSKEY': (answer) => {
        const [flags, protocol, algorithm, publicKey] = answer.split(' ');
        return `Child DNSKEY record:\n` +
            `Flags: ${flags}\n` +
            `Protocol: ${protocol}\n` +
            `Algorithm: ${algorithm} (${getDNSKeyAlgorithm(algorithm)})\n` +
            `Public Key: ${publicKey}`;
    },
    'CDS': (answer) => {
        const [keyTag, algorithm, digestType, digest] = answer.split(' ');
        return `Child DS record:\n` +
            `Key Tag: ${keyTag}\n` +
            `Algorithm: ${algorithm} (${getDNSKeyAlgorithm(algorithm)})\n` +
            `Digest Type: ${digestType}\n` +
            `Digest: ${digest}`;
    },
    'CERT': (answer) => {
        const [certType, keyTag, algorithm, cert] = answer.split(' ');
        return `Certificate record:\n` +
            `Type: ${certType}\n` +
            `Key Tag: ${keyTag}\n` +
            `Algorithm: ${algorithm}\n` +
            `Certificate: ${cert}`;
    },
    'CNAME': (answer) => `Alias to: ${answer}`,
    'DHCID': (answer) => `DHCP Identity: ${answer}`,
    'DLV': (answer) => {
        const [keyTag, algorithm, digestType, digest] = answer.split(' ');
        return `DNSSEC Lookaside Validation record:\n` +
            `Key Tag: ${keyTag}\n` +
            `Algorithm: ${algorithm}\n` +
            `Digest Type: ${digestType}\n` +
            `Digest: ${digest}`;
    },
    'DNAME': (answer) => `Domain delegation: ${answer}`,
    'DNSKEY': (answer) => {
        const [, protocol, algorithm, publicKey] = answer.split(' ');
        return `DNSSEC key:\n` +
            // `Flags: ${flags}\n` +
            `Protocol: ${protocol}\n` +
            `Algorithm: ${algorithm} (${getDNSKeyAlgorithm(algorithm)})\n` +
            `Public Key: ${publicKey}`;
    },
    'DS': (answer) => {
        const [keyTag, algorithm, digestType, digest] = answer.split(' ');
        return `Delegation Signer:\n` +
            `Key Tag: ${keyTag}\n` +
            `Algorithm: ${algorithm} (${getDNSKeyAlgorithm(algorithm)})\n` +
            `Digest Type: ${digestType}\n` +
            `Digest: ${digest}`;
    },
    'EUI48': (answer) => `EUI-48 address: ${answer}`,
    'EUI64': (answer) => `EUI-64 address: ${answer}`,
    'HINFO': (answer) => {
        const [cpu, os] = answer.split(' ');
        return `Host information:\n` +
            `CPU: ${cpu}\n` +
            `OS: ${os}`;
    },
    'HIP': (answer) => {
        const [algorithm, hit, publicKey, servers] = answer.split(' ');
        return `Host Identity Protocol:\n` +
            `Algorithm: ${algorithm}\n` +
            `HIT: ${hit}\n` +
            `Public Key: ${publicKey}\n` +
            `Rendezvous Servers: ${servers}`;
    },
    'IPSECKEY': (answer) => {
        const [precedence, gwType, algorithm, gateway, publicKey] = answer.split(' ');
        return `IPSEC Key:\n` +
            `Precedence: ${precedence}\n` +
            `Gateway Type: ${gwType}\n` +
            `Algorithm: ${algorithm}\n` +
            `Gateway: ${gateway}\n` +
            `Public Key: ${publicKey}`;
    },
    'KEY': (answer) => {
        const [flags, protocol, algorithm, publicKey] = answer.split(' ');
        return `Security Key:\n` +
            `Flags: ${flags}\n` +
            `Protocol: ${protocol}\n` +
            `Algorithm: ${algorithm}\n` +
            `Public Key: ${publicKey}`;
    },
    'KX': (answer) => {
        const [preference, exchanger] = answer.split(' ');
        return `Key Exchanger:\n` +
            `Preference: ${preference}\n` +
            `Exchanger: ${exchanger}`;
    },
    'LOC': (answer) => {
        const [latitude, longitude, altitude, size, hp, vp] = answer.split(' ');
        return `Location Information:\n` +
            `Latitude: ${latitude}\n` +
            `Longitude: ${longitude}\n` +
            `Altitude: ${altitude}m\n` +
            `Size: ${size}m\n` +
            `Horizontal Precision: ${hp}m\n` +
            `Vertical Precision: ${vp}m`;
    },
    'MX': (answer) => {
        const [priority, server] = answer.split(' ');
        return `Mail server: ${server} (priority: ${priority})`;
    },
    'NAPTR': (answer) => {
        const [order, preference, flags, service, regexp, replacement] = answer.split(' ');
        return `Naming Authority Pointer:\n` +
            `Order: ${order}\n` +
            `Preference: ${preference}\n` +
            `Flags: ${flags}\n` +
            `Service: ${service}\n` +
            `Regular Expression: ${regexp}\n` +
            `Replacement: ${replacement}`;
    },
    'NS': (answer) => `Nameserver: ${answer}`,
    'NSEC': (answer) => {
        const [nextDomain, types] = answer.split(' ');
        return `Next Secure record:\n` +
            `Next Domain Name: ${nextDomain}\n` +
            `Type Bit Maps: ${types}`;
    },
    'NSEC3': (answer) => {
        const [algorithm, flags, iterations, salt, nextHash, types] = answer.split(' ');
        return `NSEC3 record:\n` +
            `Hash Algorithm: ${algorithm}\n` +
            `Flags: ${flags}\n` +
            `Iterations: ${iterations}\n` +
            `Salt: ${salt}\n` +
            `Next Hashed Owner Name: ${nextHash}\n` +
            `Type Bit Maps: ${types}`;
    },
    'NSEC3PARAM': (answer) => {
        const [algorithm, flags, iterations, salt] = answer.split(' ');
        return `NSEC3 Parameters:\n` +
            `Hash Algorithm: ${algorithm}\n` +
            `Flags: ${flags}\n` +
            `Iterations: ${iterations}\n` +
            `Salt: ${salt}`;
    },
    'PTR': (answer) => `Points to hostname: ${answer}`,
    'RRSIG': (answer) => {
        const [typeCovered, algorithm, labels, ttl, expiration, inception, keyTag, signerName, signature] = answer.split(' ');
        return `DNSSEC Signature:\n` +
            `Type Covered: ${typeCovered}\n` +
            `Algorithm: ${algorithm} (${getDNSKeyAlgorithm(algorithm)})\n` +
            `Labels: ${labels}\n` +
            `TTL: ${ttl}\n` +
            `Signature Expiration: ${expiration}\n` +
            `Signature Inception: ${inception}\n` +
            `Key Tag: ${keyTag}\n` +
            `Signer's Name: ${signerName}\n` +
            `Signature: ${signature}`;
    },
    'RP': (answer) => {
        const [mailbox, txtRecord] = answer.split(' ');
        return `Responsible Person:\n` +
            `Mailbox: ${mailbox}\n` +
            `TXT Record: ${txtRecord}`;
    },
    'SIG': (answer) => {
        const [typeCovered, algorithm, labels, ttl, expiration, inception, keyTag, signerName, signature] = answer.split(' ');
        return `Security Signature:\n` +
            `Type Covered: ${typeCovered}\n` +
            `Algorithm: ${algorithm}\n` +
            `Labels: ${labels}\n` +
            `TTL: ${ttl}\n` +
            `Signature Expiration: ${expiration}\n` +
            `Signature Inception: ${inception}\n` +
            `Key Tag: ${keyTag}\n` +
            `Signer's Name: ${signerName}\n` +
            `Signature: ${signature}`;
    },
    'SOA': (answer) => {
        const [primary, contact, serial, refresh, retry, expire, ttl] = answer.split(' ');
        return `Start of Authority:\n` +
            `Primary nameserver: ${primary}\n` +
            `Admin contact: ${contact}\n` +
            `Serial: ${serial}\n` +
            `Refresh: ${refresh} seconds\n` +
            `Retry: ${retry} seconds\n` +
            `Expire: ${expire} seconds\n` +
            `TTL: ${ttl} seconds`;
    },
    'SPF': (answer) => `Sender Policy Framework: ${answer}`,
    'SRV': (answer) => {
        const [priority, weight, port, target] = answer.split(' ');
        return `Service record:\n` +
            `Priority: ${priority}\n` +
            `Weight: ${weight}\n` +
            `Port: ${port}\n` +
            `Target: ${target}`;
    },
    'SSHFP': (answer) => {
        const [algorithm, fpType, fingerprint] = answer.split(' ');
        return `SSH Fingerprint:\n` +
            `Algorithm: ${algorithm}\n` +
            `Fingerprint Type: ${fpType}\n` +
            `Fingerprint: ${fingerprint}`;
    },
    'TA': (answer) => {
        const [keyTag, algorithm, digestType, digest] = answer.split(' ');
        return `Trust Authority:\n` +
            `Key Tag: ${keyTag}\n` +
            `Algorithm: ${algorithm}\n` +
            `Digest Type: ${digestType}\n` +
            `Digest: ${digest}`;
    },
    'TKEY': (answer) => {
        const [algorithm, inception, expiration, mode, error, keyData] = answer.split(' ');
        return `Transaction Key:\n` +
            `Algorithm: ${algorithm}\n` +
            `Inception: ${inception}\n` +
            `Expiration: ${expiration}\n` +
            `Mode: ${mode}\n` +
            `Error: ${error}\n` +
            `Key Data: ${keyData}`;
    },
    'TLSA': (answer) => {
        const [usage, selector, matchingType, certificateData] = answer.split(' ');
        return `TLSA Certificate Association:\n` +
            `Certificate Usage: ${usage}\n` +
            `Selector: ${selector}\n` +
            `Matching Type: ${matchingType}\n` +
            `Certificate Data: ${certificateData}`;
    },
    'TSIG': (answer) => {
        const [algorithm, timeSigned, fudge, signature, originalId, error] = answer.split(' ');
        return `Transaction Signature:\n` +
            `Algorithm: ${algorithm}\n` +
            `Time Signed: ${timeSigned}\n` +
            `Fudge: ${fudge}\n` +
            `Signature: ${signature}\n` +
            `Original ID: ${originalId}\n` +
            `Error: ${error}`;
    },
    'TXT': (answer) => `Text information: ${answer}`,
    'URI': (answer) => {
        const [priority, weight, target] = answer.split(' ');
        return `URI record:\n` +
            `Priority: ${priority}\n` +
            `Weight: ${weight}\n` +
            `Target URI: ${target}`;
    },
    'ZONEMD': (answer) => {
        const [serial, scheme, algorithm, digest] = answer.split(' ');
        return `Zone Message Digest:\n` +
            `Serial: ${serial}\n` +
            `Scheme: ${scheme}\n` +
            `Algorithm: ${algorithm}\n` +
            `Digest: ${digest}`;
    },
    'HTTPS': (answer) => {
        const [priority, targetName, ...params] = answer.split(' ');

        // Parse the parameters which come in key=value format
        const paramMap = params.reduce((acc, param) => {
            const [key, value] = param.split('=');
            acc[key] = value;
            return acc;
        }, {});

        let interpretation = `HTTPS Service Binding:\n` +
            `Priority: ${priority}\n` +
            `Target Name: ${targetName}\n`;

        // Interpret known parameters
        if (paramMap.alpn) {
            const protocols = paramMap.alpn.split(',');
            interpretation += `ALPN: ${protocols.join(', ')} (Application-Layer Protocol Negotiation)\n`;
        }

        if (paramMap.port) {
            interpretation += `Port: ${paramMap.port}\n`;
        }

        if (paramMap.ipv4hint) {
            interpretation += `IPv4 Hint: ${paramMap.ipv4hint}\n`;
        }

        if (paramMap.ipv6hint) {
            interpretation += `IPv6 Hint: ${paramMap.ipv6hint}\n`;
        }

        if (paramMap.ech) {
            interpretation += `Encrypted Client Hello Configuration: ${paramMap.ech}\n`;
        }

        if (paramMap.mandatory) {
            interpretation += `Mandatory Parameters: ${paramMap.mandatory}\n`;
        }

        return interpretation;
    },
};

export function interpretDNSRecord(recordType, answer) {
    recordType = recordType.toUpperCase();

    if (interpretations[recordType]) {
        try {
            return interpretations[recordType](answer);
        }
        catch (e) {
            console.log(e)
        }
    }

    return `Record type ${recordType}: ${answer}`;
}