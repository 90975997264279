import {
    Table, Descriptions, List, Spin, Empty,
    Popover, Button, Tabs, Tag, Typography, Tooltip
} from "antd"
import { useCallback, useMemo, createContext, useContext, useState, Fragment } from "react"
import * as uuid from "uuid"
import { isEmail } from "validator"
import ipaddr from "ipaddr.js"
import isScalar from "is-scalar"

import IpLink from "./link/Ip"
import { getCountryNameWithFlag } from "./CountryName"
import EmailPopover from "./EmailPopover"

const URL_REGEX =
    /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/;

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_COLS_NUM = 5;
const MAX_TEXT_LENGTH = 50;
const RandomDataContext = createContext();
const { Title } = Typography;

function addRowKey(list) {
    return list.map(row => {
        return {
            id: row.id || uuid.v4(),
            ...row
        }
    })
}

function uc(str) {
    return str[0].toUpperCase() + str.substring(1);
}

function isDataObject(value) {
    if (isScalar(value)) {
        return false;
    }
    if (Array.isArray(value)) {
        return false;
    }
    return true;
}

function isPlainList(value) {
    return value.findIndex(v => !isScalar(v)) === -1;
}

function renderString(value, maxLength = MAX_TEXT_LENGTH) {
    if (value.indexOf(".") !== -1 && ipaddr.IPv4.isValid(value)) {
        return <IpLink ip={value} />
    }
    if (value.indexOf("http") === 0 && value.match(URL_REGEX)) {
        let children = value;
        if (children.length > 40) {
            children = children.substring(0, 40) + "...";
        }
        return (
            <Tooltip title={value}>
                <a href={value} target="_blank" rel="noreferrer">{children}</a>
            </Tooltip>
        )
    }

    if (maxLength && value.length > maxLength) {
        return (
            <ExpandableText>{value}</ExpandableText>
        )
    }

    return value;
}

function renderKey(key) {

    if (key.indexOf('_') === -1) {
        return uc(key);
    }

    return uc(key).replace(/_/g, ' ');
}

function defaultValueRenderer(key, value, children = null) {
    // console.log(key)
    if (typeof value !== "string") {
        // return value;
        return children;
    }
    if (key && typeof key === "string") {
        if ((key.toLowerCase() === "country" || key.toLowerCase().indexOf("/country") !== -1) &&
            value.length === 2) {
            return getCountryNameWithFlag(value);
        }
        if (key.toLowerCase().indexOf("email") !== -1 && isEmail(value)) {
            return (<EmailPopover email={value} children={children || value} />)
        }
    }
    //return value;
    return children;
}

function renderScalarValue(value, path, maxLength) {
    if (typeof value === "string") {
        value = defaultValueRenderer(path, value) || value;
        if (typeof value === "string") {
            return renderString(value, maxLength);
        }
        else return value;
    }
    if (typeof value === "number") {
        return "" + value;
    }
    if (typeof value === "boolean") {
        return value ? "true" : "false";
    }
    if (value === null) {
        return "null";
    }
    if (value === undefined) {
        return "";
    }
}

function getRowKey(row) {
    if (row.id) {
        return row.id;
    }
    return uuid.v4();
}


function ExpandableText({ children, length = MAX_TEXT_LENGTH }) {

    const preview = useMemo(() => children.substring(0, length), [children, length])

    if (children.length <= length) {
        return children;
    }

    return (
        <Popover
            content={children}
            rootClassName="random-data-long-text">
            {preview}...
        </Popover>
    )
}


function PlainList({ data, path, level = 1 }) {

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const context = useContext(RandomDataContext);
    const { textMaxLength } = context;

    const rows = useMemo(
        () => {
            return addRowKey(
                data.filter(value => value !== "")
                    .slice(page * pageSize, (page * pageSize) + pageSize)
                    .map(children => ({ children }))
            );
        },
        [data, page, pageSize]
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const renderItem = useCallback(
        (row) => (
            <List.Item>
                {renderScalarValue(row.children, path, textMaxLength)}
            </List.Item>
        ),
        [textMaxLength, path]
    );

    const pagination = useMemo(
        () => {
            return {
                hideOnSinglePage: true,
                position: ["bottomCenter"],
                total: data.length,
                defaultPageSize: DEFAULT_PAGE_SIZE,
                onChange: onPaginationChange,
                pageSize: pageSize
            }
        },
        [data, onPaginationChange, pageSize]
    );

    if (data.length === 0) {
        return (
            <Tag bordered={false}>No data</Tag>
        )
    }

    return (
        <List
            size="small"
            rowKey={getRowKey}
            grid={{ column: 3 }}
            renderItem={renderItem}
            pagination={pagination}
            dataSource={rows} />
    )
}

function DataTable({ data, level = 1, path }) {

    const context = useContext(RandomDataContext);
    const { getColumns, keyNames, usePopups, popupAtLevel, textMaxLength } = context;
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const isPlain = useMemo(() => data.findIndex(v => !isScalar(v)) === -1, [data]);
    const isMixedList = useMemo(
        () => {
            if (isPlain) {
                return false;
            }
            const hasPlain = data.findIndex(v => isScalar(v)) !== -1;
            const hasObjects = data.findIndex(v => isDataObject(v)) !== -1;
            return hasObjects && hasPlain;
        },
        [data, isPlain]
    );

    const renderListItem = useCallback(
        (value) => {
            if (isScalar(value)) {
                return renderScalarValue(value, path, textMaxLength);
            }
            if (Array.isArray(value) && isPlainList(value)) {
                return (
                    <PlainList data={value} path={path} level={level + 1} />
                )
            }
            if (Array.isArray(value)) {
                if (usePopups && level > popupAtLevel) {
                    return (
                        <Popover
                            rootClassName="random-data-pop"
                            content={<DataTable data={value} level={level + 1} path={path} />}
                            trigger="click">
                            <Button children="Show" size="small" type="primary" />
                        </Popover>
                    )
                }
                return (
                    <DataTable data={value} level={level + 1} path={path} />
                )
            }
            if (usePopups) {
                return (
                    <Popover
                        content={<DataObject data={value} level={level + 1} path={path} />}
                        trigger="click"
                        rootClassName="random-data-pop">
                        <Button children="Show" size="small" type="primary" />
                    </Popover>
                )
            }
            return (
                <DataObject data={value} level={level + 1} path={path} />
            )
        },
        [level, path, usePopups, popupAtLevel, textMaxLength]
    );

    const { columns, expandColumns } = useMemo(
        () => {
            if (data.length === 0 || isPlain || isMixedList) {
                return { columns: [], expandColumns: [] };
            }
            const first = data[0];
            const keys = Object.keys(first);
            let expandColumns = [];
            let columns = [];

            if (getColumns) {
                columns = getColumns(path, data);
            }
            if (columns.length === 0) {
                keys.slice(0, DEFAULT_COLS_NUM).forEach(key => {
                    columns.push({
                        key,
                        title: keyNames[path + "/*/" + key] || keyNames[key] || renderKey(key),
                        dataIndex: key,
                        render: renderListItem
                    })
                });
            }
            if (columns.length < keys.length) {
                expandColumns = keys;
            }

            return { columns, expandColumns };
        },
        [data, isPlain, isMixedList, renderListItem, keyNames, getColumns, path]
    );

    const rows = useMemo(
        () => {
            if (isPlain || !data) {
                return [];
            }
            return addRowKey(data.slice(page * pageSize, (page * pageSize) + pageSize));
        },
        [data, isPlain, page, pageSize]
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const renderExpanded = useCallback(
        (row) => {
            const data = {};
            expandColumns.forEach(key => {
                data[key] = row[key];
            });
            return (
                <DataObject data={data} path={path + "/*"} level={level + 1} />
            )
        },
        [expandColumns, level, path]
    );

    const pagination = useMemo(
        () => {
            return {
                hideOnSinglePage: true,
                position: ["bottomCenter"],
                total: data.length,
                defaultPageSize: DEFAULT_PAGE_SIZE,
                onChange: onPaginationChange,
                pageSize: pageSize
            }
        },
        [data, onPaginationChange, pageSize]
    );

    const expandable = useMemo(
        () => {
            if (expandColumns.length === 0) {
                return false;
            }
            return {
                expandedRowRender: renderExpanded,
                rowExpandable: () => true,
            };
        },
        [renderExpanded, expandColumns]
    );

    if (data.length === 0) {
        return <Tag bordered={false}>No data</Tag>;
    }

    if (isMixedList) {
        return (
            <List
                rowKey={getRowKey}
                dataSource={rows}
                renderItem={renderListItem}
                pagination={pagination}
                bordered
                size="small" />
        )
    }

    return (
        <Table
            rowKey={getRowKey}
            columns={columns}
            dataSource={rows}
            bordered
            scroll={{ x: true }}
            pagination={pagination}
            expandable={expandable}
            size="small" />
    )
}

function DataObject({ data, level = 1, path }) {
    const context = useContext(RandomDataContext);
    const { keyNames, renderPath, titleLevel = 4 } = context;

    const { items, lists } = useMemo(
        () => {
            const keys = data ? Object.keys(data) : [];
            const lists = [];
            const items = [];

            keys.forEach(key => {
                let keyData = data[key];
                let children = defaultValueRenderer(
                    key,
                    keyData,
                    renderPath ? renderPath(key, keyData) : null
                );
                //console.log("children 1", children);
                if (children === null) {
                    children = <AnyData data={keyData} path={path + '/' + key} level={level + 1} />;
                }
                const item = {
                    key,
                    label: keyNames[path + "/" + key] || keyNames[key] || renderKey(key),
                    children
                };

                if (Array.isArray(keyData) && !isPlainList(keyData)) {
                    lists.push(item);
                }
                else {
                    items.push(item);
                }
            });

            return { items, lists };
        },
        [data, level, path, renderPath, keyNames]
    );

    if (items.length === 0 && lists.length === 0) {
        return <Tag bordered={false}>No data</Tag>;
    }

    return (
        <>
            {items.length > 0 &&
                <Descriptions
                    items={items}
                    bordered
                    column={1}
                    size="small" />}

            {lists.map((list) => (
                <Fragment key={list.key}>
                    {(items.length > 0 || lists.length > 1) && <Title level={titleLevel}>{list.label}</Title>}
                    {list.children}
                </Fragment>
            ))}
        </>
    )
}


function AnyData({ data, level = 1, path }) {
    const context = useContext(RandomDataContext);
    const { popupAtLevel, usePopups, textMaxLength } = context;


    const children = useMemo(
        () => {
            if (Array.isArray(data)) {
                if (isPlainList(data)) {
                    return (
                        <PlainList data={data} path={path} level={level} />
                    )
                }
                if (usePopups && level > popupAtLevel) {
                    return (
                        <Popover
                            content={<DataTable data={data} level={level} path={path} />}
                            trigger="click"
                            rootClassName="random-data-pop">
                            <Button children="Show" size="small" type="primary" />
                        </Popover>
                    )
                }
                return (
                    <DataTable data={data} level={level} path={path} />
                )
            }
            else {
                if (isScalar(data)) {
                    return renderScalarValue(data, path, textMaxLength);
                }
                return (
                    <DataObject data={data} level={level} path={path} />
                )
            }
        },
        [level, data, path, textMaxLength, usePopups, popupAtLevel]
    );

    return children;
}


function RootDataObject({ data }) {

    const context = useContext(RandomDataContext);
    const { keyNames, renderPath, renderBefore, renderAfter } = context;

    const items = useMemo(
        () => {

            const keys = data ? Object.keys(data) : [];

            return keys.map(key => {
                let keyData = data[key];

                const before = renderBefore ? renderBefore(key, keyData) : null;
                const after = renderAfter ? renderAfter(key, keyData) : null;
                let children = defaultValueRenderer(
                    key,
                    keyData,
                    renderPath ? renderPath(key, keyData) : null
                );
                //console.log("children 2", children);
                if (children === null) {
                    children = <AnyData data={keyData} path={key} />;
                }

                return {
                    key,
                    label: keyNames[key] || renderKey(key),
                    children: (
                        <>
                            {before}
                            {children}
                            {after}
                        </>
                    )
                };
            });
        },
        [data, renderPath, keyNames, renderBefore, renderAfter]
    );

    if (items.length === 0) {
        return null;
    }

    return (
        <Tabs items={items} />
    )
}

function RandomData({
    data,
    loading = false,
    skipRootTabs = false,
    popupAtLevel = 2,
    usePopups = true,
    titleLevel = 4,
    textMaxLength = MAX_TEXT_LENGTH,
    keyNames = {},
    renderPath,
    renderBefore,
    renderAfter,
    getColumns }) {

    const RootComponent = useMemo(
        () => Array.isArray(data) ?
            DataTable :
            skipRootTabs ?
                AnyData :
                RootDataObject,
        [data, skipRootTabs]
    );

    const context = useMemo(
        () => {
            return {
                keyNames,
                renderPath,
                renderBefore,
                renderAfter,
                getColumns,
                popupAtLevel,
                usePopups,
                titleLevel,
                textMaxLength
            }
        },
        [keyNames, renderPath, getColumns, renderBefore,
            renderAfter, usePopups, popupAtLevel,
            titleLevel, textMaxLength]
    );

    return (
        <Spin spinning={loading}>
            <RandomDataContext.Provider value={context}>
                <div className="random-data">
                    {data ? <RootComponent data={data} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div>
            </RandomDataContext.Provider>
        </Spin>
    )
}

export default RandomData