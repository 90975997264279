import { useMemo, useState, useCallback } from "react"
import { Typography, Table, Input, Button, Segmented, Tooltip } from "antd"
import { SearchOutlined, CheckOutlined, DownloadOutlined } from "@ant-design/icons"

import Page from "components/Page"
import DomainExpandable from "components/DomainExpandable"
import HuntReportFormDialog from "components/hunt/HuntReportForm"


import supabase from "lib/supabase/main"
import useQuery from "hooks/useQuery"
import { Link } from "react-router-dom"
import formatNumber from "lib/format/number"
import { Portal } from "components/Portal"
import useAppKey from "hooks/useAppKey"
import downloadCsv from "lib/downloadCsv"
import { data2csv } from "lib/csv"
import renderGMT from "lib/renderGMT"
import ThreatName from "components/ThreatName"

const columns = [
    {
        dataIndex: "domain",
        key: "domain",
        title: "Domain",
        render: domain => (<Link to={`/reports/domain/${domain}`} children={domain} />)
    },
    {
        dataIndex: "hunted_domain",
        key: "hunted_domain",
        title: "Hunted domain",
        render: domain => (<Link to={`/reports/domain/${domain}`} children={domain} />)
    },
    {
        dataIndex: "dns_log_counter",
        key: "dns_log_counter",
        title: "DNS requests",
        width: '100px',
        render: formatNumber
    },
    {
        dataIndex: "registrant_email",
        key: "registrant_email",
        title: "Registrant email",
        render: email => {
            const sp = new URLSearchParams();
            sp.set("queryBy", "customerEmail");
            sp.set("query", email);
            return (<Link to={`/registry/domains?${sp.toString()}`} children={email} />)
        }
    },
    {
        dataIndex: "threat",
        key: "threat",
        title: "Threat",
        render: (threat, row) => {
            return (
                <ThreatName id={row.threat_id} name={threat} />
            )
        }
    },
    {
        dataIndex: "ioc_found",
        key: "ioc_found",
        title: "IOC record found",
        width: '150px',
        render: v => v ? "Yes" : "No"
    },
    {
        dataIndex: "in_queue",
        key: "in_queue",
        title: "In queue",
        width: '100px',
        render: v => v ? "Yes" : "No"
    },
    {
        dataIndex: "added_at",
        key: "added_at",
        title: "Added at",
        //width: '100px',
        render: v => renderGMT(v)
    }
]

const iocOptions = [
    {
        key: "found",
        value: true,
        label: "w/IOC"
    },
    {
        key: "not-found",
        value: false,
        label: "wo/IOC"
    }
]

async function loadQueueRecords(domains) {

    let all = [];
    const allDomains = [...domains];

    while (allDomains.length > 0) {
        const slice = allDomains.splice(0, 100);
        const { data } = await supabase.from("hunt_report_queue")
            .select()
            .in("domain", slice);
        all = [...all, ...data];
    }

    return all;
}

async function loadDomainRecords(domains) {
    let all = [];
    const allDomains = [...domains];

    while (allDomains.length > 0) {
        const slice = allDomains.splice(0, 100);
        const { data } = await supabase.from("threat_domain")
            .select()
            .in("domain", slice);
        all = [...all, ...data];
    }

    return all;
}

async function loadRelatedDomains({ page, pageSize = 20, query = "", iocFound = null }) {
    const req = supabase.from("registrar_related_hunt")
        .select("*", { count: "exact" })
        .order("added_at", { ascending: false })
        .range(page * pageSize, (page * pageSize) + pageSize - 1);

    if (query) {
        req.like("domain", `%${query}%`);
    }
    if (iocFound !== null) {
        iocFound = !!iocFound;
        req.eq("ioc_found", iocFound);
    }

    const { data, error, count } = await req;

    if (error) {
        console.error(error);
        return { data: [], error: error, columns: [], count: 0 };
    }

    const domains = data.map(row => row.domain);
    const hunted = data.map(row => row.hunted_domain);

    if (domains.length > 0) {
        const queue = await loadQueueRecords(domains);
        data.forEach(row => {
            const q = queue.find(r => r.domain === row.domain);
            row.in_queue = !!q;
        });
    }

    if (hunted.length > 0) {
        const stats = await loadDomainRecords(hunted);
        data.forEach(row => {
            const s = stats.find(r => r.domain === row.hunted_domain);
            row.dns_log_counter = s?.dns_log_counter || 0;
            row.tarpit_log_counter = s?.tarpit_log_counter || 0;
        });
    }

    return {
        data,
        columns: Object.keys(data[0] || {}),
        error,
        count
    };
}

async function loadAllRelatedDomains(options) {

    let domains = [];
    let columns = [];

    let page = 0;
    while (true) {
        const { data, columns: dataColumns } = await loadRelatedDomains({
            ...options,
            page,
            pageSize: 1000
        });
        page++;

        if (columns.length === 0) {
            columns = [...dataColumns];
        }

        if (data.length === 0) {
            break;
        }

        domains = [...domains, ...data];
    }

    return { domains, columns };
}

function RelatedDomains({ toolbarSize = "large" }) {

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [query, setQuery] = useState("");
    const [appliedQuery, setAppliedQuery] = useState("");
    const [iocFound, setIocFound] = useState(false);
    const [queueInitialData, setQueueInitialData] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const isAdmin = useAppKey("isAdmin");

    const { data, count, isLoading, refetch } = useQuery(
        loadRelatedDomains,
        [page, pageSize, appliedQuery, iocFound],
        {
            params: {
                page,
                pageSize,
                query: appliedQuery,
                iocFound
            }
        }
    );

    const renderExpanded = useCallback(
        (row) => {

            return (
                <div style={{ padding: 10, paddingTop: 0 }}>
                    <DomainExpandable
                        domain={row.domain} />
                </div>
            )
        },
        []
    );

    const expandable = useMemo(
        () => {
            return {
                expandedRowRender: renderExpanded,
                rowExpandable: () => true,
            }
        },
        [renderExpanded]
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const pagination = useMemo(
        () => {
            return {
                position: ["bottomCenter"],
                total: count,
                defaultPageSize: 50,
                onChange: onPaginationChange,
                pageSize
            }
        },
        [pageSize, count, onPaginationChange]
    );

    const onSearchClick = useCallback(
        () => {
            setPage(0);
            setAppliedQuery(query);
        },
        [setAppliedQuery, query]
    );

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        []
    );

    const onIocChange = useCallback(
        (v) => {
            setPage(0);
            setIocFound(v);
        },
        []
    );

    const onCreateReportClick = useCallback(
        (row) => {
            setQueueInitialData({
                domain: row.domain,
                malwareId: row.threat_id
            });
        },
        []
    );

    const onDownloadClick = useCallback(
        async () => {
            setDownloading(true);
            const { domains, columns } = await loadAllRelatedDomains({ query: appliedQuery, iocFound });
            const csv = data2csv(columns, domains);
            downloadCsv(csv, "related_domains.csv");
            setDownloading(false);
        },
        [appliedQuery, iocFound]
    );

    const onReportFormClose = useCallback(
        () => {
            setQueueInitialData(null);
        },
        []
    );

    const onReportFormSave = useCallback(
        () => {
            setQueueInitialData(null);
            refetch();
        },
        [refetch]
    );

    const tableColumns = useMemo(
        () => {

            if (!isAdmin) {
                return [...columns];
            }

            return [
                ...columns,
                {
                    key: "actions",
                    dataIndex: "domain",
                    title: "",
                    className: "table-cell-collapse",
                    render: (domain, row) => {

                        if (row.in_queue) {
                            return null;
                        }

                        return (
                            <Tooltip title="Submit to hunt queue">
                                <Button
                                    size="small"
                                    icon={<CheckOutlined />}
                                    onClick={() => onCreateReportClick(row)} />
                            </Tooltip>
                        )
                    }
                }
            ]
        },
        [onCreateReportClick, isAdmin]
    );


    return (
        <Page className="page-related-domains">
            <Portal host="header">
                <Typography.Title level={1}>Related domains hunt</Typography.Title>
                <div className="toolbar">
                    <Segmented
                        size={toolbarSize}
                        options={iocOptions}
                        value={iocFound}
                        onChange={onIocChange} />
                    <Input
                        allowClear
                        size={toolbarSize}
                        prefix={<SearchOutlined />}
                        onChange={onQueryChange}
                        value={query} />
                    <Button
                        size={toolbarSize}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={onSearchClick}
                        children="Search" />
                    <Tooltip title="Download csv">
                        <Button
                            onClick={onDownloadClick}
                            loading={downloading}
                            disabled={downloading || isLoading}
                            size={toolbarSize}
                            icon={<DownloadOutlined />} />
                    </Tooltip>
                </div>
            </Portal>

            <Table
                sticky
                size="small"
                bordered
                tableLayout="auto"
                loading={isLoading}
                dataSource={data}
                columns={tableColumns}
                rowKey="domain"
                pagination={pagination}
                expandable={expandable} />
            <HuntReportFormDialog
                open={!!queueInitialData}
                initialData={queueInitialData}
                onClose={onReportFormClose}
                onSave={onReportFormSave} />
        </Page>
    )
}

export default RelatedDomains