import { useCallback, useState, useMemo, useContext, createContext, useRef } from "react";
import {
    Button, Table, Flex, Popconfirm, Descriptions, Spin, Pagination,
    Modal, Input, Tag, Progress, App as AntApp, Typography,
    Tooltip
} from "antd"
import {
    EditOutlined, DeleteOutlined, CloseCircleOutlined,
    SyncOutlined, CheckCircleOutlined, HomeOutlined,
    ApiOutlined, ExportOutlined, ExclamationCircleFilled,
    SearchOutlined
} from "@ant-design/icons"
import { useOn } from "@kuindji/observable-react"

import Page from "components/Page"
import CompanyEditorDialog from "components/company/Editor"

import IpRegistryDialog from "components/selector/IpRegistryDialog"
import UserDialog from "components/selector/UserDialog"
import Watchlists from "components/watchlist/List"
import WatchlistEditor from "components/watchlist/Editor"

import Context from "lib/Context"
import useQuery from "hooks/useQuery"
import supabase from "lib/supabase/main"
import aws from "lib/aws"
import ButtonLink from "components/ButtonLink"
import { Portal } from "components/Portal"
import ApiKeyDialog from "components/dialog/ApiKey"
import PotentialPhishingDomains from "components/table/PotentialPhishingDomains"
// import useUpdateEffect from "hooks/useUpdateEffect";
import useDictRef from "hooks/useDictRef"
import checkIsIp from "lib/isIp"
import checkIsCidr from "lib/isCidr"
import loadOtxData from "api/otx"

const ADDR_PER_PAGE = 50;
const userCache = [];
const CompaniesContext = createContext();

async function updateUserCache(userIds) {
    const users = await supabase.functions.invoke("backend", {
        method: "POST",
        body: {
            controller: "users/list",
            payload: {
                id: userIds,
                limit: false
            }
        }
    })
        .then(response => {
            const { data: ctlData } = response;
            const { data } = ctlData || {};
            return data?.rows || [];
        });

    users.forEach(u => userCache.push(u));
}


async function fetchCompanies({ query }) {
    const isIpQuery = !!query && (checkIsCidr(query) || checkIsIp(query));
    const graph = `
        id, 
        name, 
        logo,
        company_domain!company_domain_company_id_fkey(
            domain
        ),
        company_domain_similar(
            company_id, domain, similar_domain,
            takedown_requested, request_status, status_changed_at,
            similar_domain_info:domain!company_domain_similar_similar_domain_fkey(
                domain, creation_date, expiration_date, country
            )
        ),
        ${isIpQuery ? 'company_addr!inner(addr), ' : ''}
        user_profile(
            id,
            first_name,
            last_name
        ),
        watchlist(
            id,
            matched_ip_count,
            watchlist_notification(
                active
            )
        )`;
    const req = supabase.from("company")
        .select(graph)
        .order("name", { ascending: true });

    if (query) {
        if (isIpQuery) {
            req.eq("company_addr.addr", query);
        }
        else {
            req.ilike("name", `%${query}%`);
        }
    }

    const { data: companies } = await req;
    const userIds = companies
        .map(c => c.user_profile.map(p => p.id))
        .flat()
        .filter(id => !!id)
        .filter((id, inx, self) => self.indexOf(id) === inx)
        .filter(id => userCache.findIndex(u => u.id === id) === -1);

    // const similar_domains = await fetchDomainInfo({
    //     domains: companies
    //         .map(c => c.company_domain_similar.map(ds => ds.similar_domain))
    //         .flat()
    //         .filter(d => !!d)
    //         .filter((id, inx, self) => self.indexOf(id) === inx)
    // });

    if (userIds.length > 0) {
        await updateUserCache(userIds);
    }

    companies.forEach(c => {
        // c.company_domain_similar.forEach(ds => {
        //     const d = similar_domains.find(sd => sd.domain === ds.similar_domain);
        //     if (d) {
        //         ds.domain_info = d;
        //     }
        // })
        c.user_profile.forEach(p => {
            const u = userCache.find(u => u.id === p.id);
            if (u) {
                p.email = u.email;
            }
        });

        // sort watchlists by name
        // c.watchlist = c.watchlist.sort((a, b) => a.name.localeCompare(b.name));
    });
    // console.log(companies)

    return { data: companies };
}

async function fetchCompanyAddrs({ companyId, page = 1, query }) {
    page--;
    if (query) {
        const resp = await aws.backend.post("/company/find_addr", {
            body: {
                companyId,
                query,
                offset: page * ADDR_PER_PAGE,
                limit: ADDR_PER_PAGE
            }
        });
        return {
            data: resp?.addr || [],
            count: resp?.count || 0
        }
    }
    else {
        return await supabase.from("company_addr")
            .select("*", { count: 'exact', head: false })
            .eq("company_id", companyId)
            .range(page * ADDR_PER_PAGE, (page * ADDR_PER_PAGE) + ADDR_PER_PAGE)
            .order("addr");
    }
}

async function fetchIpRegistryCidrs(entityId, offset = 0) {
    const { data: cidrs } = await supabase.from("entity_cidr")
        .select("cidr")
        .eq("entity_id", entityId)
        .range(offset, offset + 1000);
    return cidrs.map(r => r.cidr);
}

function ProgressDialog({ open, progress }) {

    const percent = useMemo(
        () => progress ? parseInt((progress.done / progress.total) * 100) : 0,
        [progress]
    );
    const done = progress?.done || 0;
    const total = progress?.total || 0;

    return (
        <Modal
            title="Update progress"
            centered
            open={open}
            footer={null}
            closable={false}>
            <Flex gap="1rem" vertical align="center">
                <p>{done} / {total}</p>
                <Progress type="line" percent={percent} />
            </Flex>
        </Modal>
    )
}

function PassiveDNSDialog({ open = false, onIpsReceived, onCancel }) {

    const [domain, setDomain] = useState("");
    const [fetching, setFetching] = useState(false);
    const onDomainChange = useCallback(
        (e) => setDomain(e.target.value),
        []
    );

    const fetchPassiveDns = useCallback(
        async () => {
            setFetching(true);
            const resp = await loadOtxData(domain);
            const pd = resp.passive_dns;
            const ips = (pd || [])
                .filter(row => checkIsCidr(row.address) || checkIsIp(row.address))
                .map(row => row.address);
            onIpsReceived && onIpsReceived(ips);
            setFetching(false);
            onCancel && onCancel();
        },
        [domain, onIpsReceived, onCancel]
    );

    const okProps = useMemo(
        () => ({
            disabled: !domain || fetching,
            loading: fetching
        }),
        [domain, fetching]
    );

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            okButtonProps={okProps}
            onOk={fetchPassiveDns}
            title="Passive DNS for domain">
            <Input
                onPressEnter={domain ? fetchPassiveDns : null}
                placeholder="domain.name"
                value={domain}
                onChange={onDomainChange} />
        </Modal>
    )
}

function IpDialog({ open, title, onClose, onSave,
    allowIpRegistry = false,
    allowPassiveDns = false }) {

    const [value, setValue] = useState("");
    const [submiting, setSubmitting] = useState(false);
    const [showIpRegistry, setShowIpRegistry] = useState(false);
    const [showPassiveDns, setShowPassiveDns] = useState(false);

    const onChange = useCallback(
        (e) => setValue(e.target.value),
        []
    );

    const onOk = useCallback(
        async () => {
            setSubmitting(true);
            await onSave(value);
            setSubmitting(false);
            setValue("");
            onClose();
        },
        [onClose, onSave, value]
    );

    const onCancel = useCallback(
        () => {
            setValue("");
            onClose();
        },
        [onClose]
    );

    const okButtonProps = useMemo(
        () => ({
            disabled: submiting,
            loading: submiting
        }),
        [submiting]
    );

    const cancelButtonProps = useMemo(
        () => ({
            disabled: submiting
        }),
        [submiting]
    );

    const onImportIpRegistryClick = useCallback(
        () => setShowIpRegistry(true),
        []
    );

    const onIpRegistryClose = useCallback(
        () => setShowIpRegistry(false),
        []
    );

    const onImportPassiveDnsClick = useCallback(
        () => setShowPassiveDns(true),
        []
    );

    const onPassiveDnsCancel = useCallback(
        () => setShowPassiveDns(false),
        []
    );

    const onIpsReceived = useCallback(
        (ips) => {
            setValue(prev => {
                const str = ips.join("\n");
                return prev ? prev + "\n" + str : str;
            })
        },
        []
    );

    const onIpRegistrySelect = useCallback(
        async (ids) => {
            if (ids.length > 0) {
                const id = ids[0];
                let cidrs = [];
                let offset = 0;

                while (true) {
                    const page = await fetchIpRegistryCidrs(id, offset);
                    if (page.length === 0) {
                        break;
                    }
                    cidrs = cidrs.concat(page);
                    offset += 1000;
                }

                setValue(cidrs.join("\n"));
            }
        },
        []
    );

    return (
        <Modal
            title={title}
            centered
            onOk={onOk}
            open={open}
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}
            onCancel={onCancel}
            closable={false}>
            <Input.TextArea
                disabled={submiting}
                style={{ minHeight: '200px' }}
                value={value}
                onChange={onChange} />
            {allowIpRegistry &&
                <>
                    <Button
                        onClick={onImportIpRegistryClick}
                        type="text"
                        disabled={submiting}
                        children="Import from Ip registry" />
                    <IpRegistryDialog
                        multiple={false}
                        onSelect={onIpRegistrySelect}
                        onClose={onIpRegistryClose}
                        open={showIpRegistry} />
                </>}
            {allowPassiveDns &&
                <>
                    <Button
                        type="text"
                        disabled={submiting}
                        children="Import passive DNS"
                        onClick={onImportPassiveDnsClick} />
                    <PassiveDNSDialog
                        open={showPassiveDns}
                        onCancel={onPassiveDnsCancel}
                        onIpsReceived={onIpsReceived} />
                </>}
        </Modal>
    )
}




function CompanyAddrs({ company }) {

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [appliedQuery, setAppliedQuery] = useState("");
    const { data: addrs, count, isLoading, refetch } = useQuery(
        fetchCompanyAddrs,
        [company, page, appliedQuery],
        {
            rowIdKey: "addr",
            params: {
                page,
                query: appliedQuery,
                companyId: company.id
            }
        }
    );

    const onDeleteAddr = useCallback(
        async (addr) => {
            if (addr) {
                await supabase.from("company_addr")
                    .update({ deleted: true, processed: false })
                    .eq("company_id", company.id)
                    .eq("addr", addr);
                refetch();
            }
        },
        [company, refetch]
    );

    const onDeleteAll = useCallback(
        async () => {
            await supabase.from("company_addr")
                .update({ deleted: true, processed: false })
                .eq("company_id", company.id);
            refetch();
        },
        [company, refetch]
    );

    const onQueryChange = useCallback(
        (e) => {
            setQuery(e.target.value);
        },
        []
    );

    const onSearchClick = useCallback(
        () => {
            setAppliedQuery(query);
        },
        [query]
    );

    if (!addrs) {
        return null;
    }

    return (
        <Spin spinning={isLoading}>
            <Flex vertical gap="0.5rem" style={{ padding: "0.5rem" }}>
                {addrs.length > 0 &&
                    <Flex gap="0.5rem">
                        <Input
                            allowClear
                            value={query}
                            onChange={onQueryChange}
                            disabled={isLoading}
                            onPressEnter={onSearchClick} />
                        <Button
                            children="Search"
                            onClick={onSearchClick}
                            disabled={isLoading}
                            loading={isLoading} />
                    </Flex>}
                <Flex gap="0.5rem" wrap="wrap">
                    {addrs.map(cc => (
                        <Tag key={cc.addr}
                            style={{ marginRight: 0 }}
                            color={cc.processed ? "success" : "processing"}
                            icon={cc.processed ? <CheckCircleOutlined /> : <SyncOutlined spin />}>
                            <Flex align="center" gap="0.5rem" rootClassName="ip-tag">
                                {cc.addr}
                                {!cc.deleted &&
                                    <Popconfirm
                                        title="Are you sure?"
                                        onConfirm={() => onDeleteAddr(cc.addr)}>
                                        <CloseCircleOutlined />
                                    </Popconfirm>}
                            </Flex>
                        </Tag>
                    ))}
                </Flex>
                {addrs.length > 0 &&
                    <Flex gap="1rem" align="center">
                        <Pagination
                            showSizeChanger={false}
                            current={page}
                            total={count}
                            hideOnSinglePage
                            pageSize={ADDR_PER_PAGE}
                            onChange={setPage} />
                        {addrs.length > 1 &&
                            <Tag style={{ cursor: "pointer" }} color="error">
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={onDeleteAll}>
                                    <Flex align="center" gap="0.5rem" rootClassName="ip-tag">
                                        Delete all
                                        <CloseCircleOutlined />
                                    </Flex>
                                </Popconfirm>
                            </Tag>}
                    </Flex>}
            </Flex>
        </Spin>
    )
}

function CompanyUsers({ company }) {

    const context = useContext(CompaniesContext);

    const onDeleteUser = useCallback(
        async (id) => {
            await supabase.from("company_user")
                .delete()
                .eq("company_id", company.id)
                .eq("user_id", id);
            context.trigger("refetch");
        },
        [company, context]
    );

    const columns = useMemo(
        () => {

            const columns = [
                {
                    dataIndex: "email",
                    key: "email",
                    title: "Email"
                },
                {
                    dataIndex: "first_name",
                    key: "name",
                    title: "Name"
                },
                {
                    dataIndex: "last_name",
                    key: "name",
                    title: "Last name"
                },
                {
                    dataIndex: "id",
                    key: "actions",
                    title: "",
                    className: "table-cell-collapse",
                    render: (id, row) => {
                        return (
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => onDeleteUser(id)}>
                                <Button icon={<DeleteOutlined />} size="small" />
                            </Popconfirm>
                        )
                    }
                }
            ];

            return columns;
        },
        [onDeleteUser]
    );

    return (
        <Table
            className="table-full-width"
            size="small"
            rowKey="id"
            columns={columns}
            dataSource={company.user_profile}
            pagination={false}
            bordered />
    )
}


function CompanyDetails({ company, onChange }) {

    const { notification } = AntApp.useApp();
    const context = useContext(CompaniesContext);
    const [showUserSelector, setShowUserSelector] = useState(false);
    const [showAddrSelector, setShowAddrSelector] = useState(false);
    const [showWatchlistForm, setShowWatchlistForm] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(null);
    const watchlistsRef = useRef();

    const onAddUserClick = useCallback(() => setShowUserSelector(true), []);
    const onUserSelectorClose = useCallback(() => setShowUserSelector(false), []);

    const onAddAddrClick = useCallback(() => setShowAddrSelector(true), []);
    const onAddrSelectorClose = useCallback(() => setShowAddrSelector(false), []);

    const onAddWatchlistClick = useCallback(() => setShowWatchlistForm(true), []);
    const onWatchlistFormClose = useCallback(() => setShowWatchlistForm(false), []);

    const onAddUser = useCallback(
        async (ids) => {
            const addIds = ids.filter(id => !company.user_profile.find(c => c.id === id));

            if (addIds.length === 0) {
                return;
            }

            await supabase
                .from("company_user")
                .insert(
                    addIds.map(id => ({
                        company_id: company.id,
                        user_id: id
                    }))
                );

            context.trigger("refetch");
        },
        [company, context]
    );

    const onAddAddr = useCallback(
        async (addrs) => {
            addrs = addrs.split(/[\s]/g)
                .map(v => v.trim())
                .filter(v => !!v);

            const pageSize = 50;
            const total = addrs.length;
            const failed = [];
            let done = 0;
            setProgress({ done, total });
            if (total > pageSize) {
                setShowProgress(true);
            }

            while (true) {
                const page = addrs.splice(0, pageSize);

                if (page.length === 0) {
                    break;
                }

                const resp = await aws.backend.post("/company/add_addr", {
                    body: {
                        addr: page,
                        companyId: company.id
                    }
                });

                done += page.length;
                setProgress({ done, total });

                Object.keys(resp.results).forEach(addr => {
                    if (!resp.results[addr]) {
                        failed.push(addr);
                    }
                })
            }

            setShowProgress(false);

            if (failed.length > 0) {
                if (failed.length < 50) {
                    notification.error({
                        message: "The following addresses were not added:",
                        description: failed.join(", ")
                    });
                }
                else {
                    notification.error({
                        message: `${failed.length} address(es) were not added`,
                        description: ""
                    })
                }
            }

            if (total > 0) {
                context.trigger("refetch");
            }
        },
        [company, context, notification]
    );

    const onWatchlistSaved = useCallback(
        () => {
            //context.trigger("refetch");
            watchlistsRef.current.refetch();
        },
        []
    );

    const items = useMemo(
        () => {
            return [
                {
                    key: "users",
                    label: (
                        <Flex vertical gap="0.5rem" align="center">
                            <span>Users</span>
                            <Button
                                size="small"
                                onClick={onAddUserClick}
                                children="Add" />
                        </Flex>
                    ),
                    children: (<CompanyUsers company={company} />)
                },
                {
                    key: "addrs",
                    label: (
                        <Flex vertical gap="0.5rem" align="center">
                            <span>Addresses</span>
                            <Button
                                onClick={onAddAddrClick}
                                size="small"
                                children="Add" />
                        </Flex>
                    ),
                    children: (<CompanyAddrs company={company} />)
                },
                {
                    key: "watchlists",
                    label: (
                        <Flex vertical gap="0.5rem" align="center">
                            <span>Watchlists</span>
                            <Button
                                onClick={onAddWatchlistClick}
                                size="small"
                                children="Add" />
                        </Flex>
                    ),
                    children: (
                        <Watchlists
                            ref={watchlistsRef}
                            companyId={company.id}
                            showToolbar={false}
                            title={null} />
                    )
                },
                {
                    key: "similar",
                    label: (<>Potential<br />Phishing Domains</>),
                    children: (
                        <PotentialPhishingDomains
                            data={company.company_domain_similar}
                            onStatusChange={onChange} />
                    )
                }
            ]
        },
        [company, onAddUserClick, onAddAddrClick, onAddWatchlistClick, onChange]
    );

    return (
        <>
            <Descriptions
                column={1}
                items={items}
                bordered
                size="small" />
            <UserDialog
                onSelect={onAddUser}
                onClose={onUserSelectorClose}
                open={showUserSelector} />
            <IpDialog
                open={showAddrSelector}
                title="Addresses"
                onSave={onAddAddr}
                allowIpRegistry
                allowPassiveDns
                onClose={onAddrSelectorClose} />
            <ProgressDialog
                open={showProgress}
                progress={progress} />
            <WatchlistEditor
                companyId={company.id}
                open={showWatchlistForm}
                onSave={onWatchlistSaved}
                onClose={onWatchlistFormClose} />
        </>
    )
}

function PageCompanies() {

    const context = useMemo(() => new Context(), []);
    const [editorOpen, setEditorOpen] = useState(false);
    const [editCompany, setEditCompany] = useState(null);
    const [query, setQuery] = useState("");
    const [appliedQuery, setAppliedQuery] = useState("");

    const ref = useDictRef({ query });
    // const [data, setData] = useState([]);
    const { data, isLoading, refetch } = useQuery(
        fetchCompanies,
        [appliedQuery],
        {
            params: {
                query: appliedQuery
            }
        }
    );

    // useUpdateEffect(
    //     () => {
    //         if (!query || !rawData) {
    //             return setData(rawData);
    //         }
    //         setTimeout(() => {
    //             const lc = query.toLowerCase();
    //             const data = rawData.filter(row => {
    //                 const index = [
    //                     row.name.toLowerCase()
    //                 ];
    //                 row.company_domain.map(d => d.domain).forEach(d => index.push(d));
    //                 return !!index.find(t => t.indexOf(lc) !== -1);
    //             }, 50);
    //             setData(data);
    //         });
    //     },
    //     [rawData, query]
    // );


    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        [setQuery]
    );

    const applyQuery = useCallback(
        () => setAppliedQuery(ref.query),
        // eslint-disable-next-line
        []
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                applyQuery();
            }
        },
        // eslint-disable-next-line
        []
    );


    const onEditorClose = useCallback(
        () => {
            setEditorOpen(false);
            setEditCompany(null);
        },
        []
    );

    const onCreateClick = useCallback(
        () => {
            setEditorOpen(true)
        },
        []
    );

    const onEditClick = useCallback(
        (row) => {
            setEditCompany(row);
            setEditorOpen(true);
        },
        []
    );

    const onApiClick = useCallback(
        (row) => {
            ApiKeyDialog.show({
                companyId: row.id
            });
        },
        []
    );

    const onDeleteCompany = useCallback(
        async (id) => {
            await supabase.from("company").delete().eq("id", id);
            refetch();
        },
        [refetch]
    );

    const columns = useMemo(
        () => {

            const columns = [
                {
                    dataIndex: "name",
                    key: "name",
                    title: "Name"
                },
                {
                    dataIndex: "company_domain",
                    key: "company_domain",
                    title: "Domains",
                    render: (domains) => {
                        return domains.map(d => d.domain).join(", ");
                    }
                },
                {
                    dataIndex: "watchlist",
                    key: "watchlist",
                    title: "Watchlists",
                    render: (w) => {
                        let active = !!w.find(w => !!w.watchlist_notification.find(wn => wn.active));
                        let cnt = w.reduce(
                            (c, a) => c + (a?.matched_ip_count || 0),
                            0
                        );
                        return active ?
                            <Tooltip title="Has new matches">
                                <ExclamationCircleFilled
                                    lassName={active ? "active" : ""} />
                            </Tooltip> :
                            cnt > 0 ?
                                <Tag children={cnt} color="warning" /> :
                                null;
                    }
                },
                {
                    dataIndex: "id",
                    key: "actions",
                    title: "",
                    className: "table-cell-collapse",
                    render: (id, row) => {
                        return (
                            <Flex gap="small" align="center">
                                <ButtonLink
                                    to={`/company/${row.id}`}
                                    size="small"
                                    type="text">
                                    <HomeOutlined />
                                </ButtonLink>
                                <Tooltip title="Edit">
                                    <Button
                                        onClick={() => onEditClick(row)}
                                        icon={<EditOutlined />}
                                        size="small" />
                                </Tooltip>
                                <Tooltip title="API access">
                                    <Button
                                        onClick={() => onApiClick(row)}
                                        icon={<ApiOutlined />}
                                        size="small" />
                                </Tooltip>
                                <Tooltip title="Offload settings">
                                    <Button
                                        onClick={() => onApiClick(row)}
                                        icon={<ExportOutlined />}
                                        size="small" />
                                </Tooltip>
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => onDeleteCompany(id)}>
                                    <Button icon={<DeleteOutlined />} size="small" />
                                </Popconfirm>
                            </Flex>
                        )
                    }
                }
            ];

            return columns;
        },
        [onDeleteCompany, onEditClick, onApiClick]
    );

    const renderExpanded = useCallback(
        (row) => (<CompanyDetails company={row} onChange={refetch} />),
        [refetch]
    );

    const expandable = useMemo(
        () => ({
            expandedRowRender: renderExpanded,
            rowExpandable: () => true,
        }),
        [renderExpanded]
    );

    useOn(context, "refetch", refetch);

    return (
        <Page className="page-companies">
            <CompaniesContext.Provider value={context}>
                <Portal host="header">
                    <Typography.Title level={1}>Companies</Typography.Title>
                    <div className="toolbar">
                        <Button
                            size="large"
                            type="primary"
                            children="Create company"
                            onClick={onCreateClick} />
                        <Input
                            disabled={isLoading}
                            size="large"
                            allowClear
                            prefix={<SearchOutlined />}
                            onKeyDown={onKeyDown}
                            onChange={onQueryChange}
                            value={query} />
                        <Button
                            size="large"
                            type="default"
                            children="Search"
                            disabled={isLoading}
                            onClick={applyQuery} />
                        <Button
                            size="large"
                            type="text"
                            disabled={isLoading}
                            icon={<SyncOutlined spin={isLoading} />}
                            onClick={refetch} />
                    </div>
                </Portal>
                <Table
                    loading={isLoading}
                    size="small"
                    rowKey="id"
                    columns={columns}
                    dataSource={data}
                    pagination={{ defaultPageSize: 20 }}
                    expandable={expandable}
                    bordered />
                <CompanyEditorDialog
                    company={editCompany}
                    onSave={refetch}
                    open={editorOpen}
                    onClose={onEditorClose} />
            </CompaniesContext.Provider>
        </Page>
    )
}

export default PageCompanies