import { useCallback } from "react"
import { Typography, Descriptions, Spin } from "antd"
import { useLocation, useNavigate } from "react-router-dom"

import Page from "components/Page"
import DnsLogTable from "components/table/DnsLog"
import ColumnList from "components/ColumnList"
import TopVictims from "components/report/TopVictims"
import DomainByDate from "components/report/DomainByDate"
import ThreatFoxIocTable from "components/table/ThreatFoxIoc"
import TopThreatDomainCountries from "components/report/TopThreatDomainCountries"
import TarpitLog from "components/table/TarpitLog"
import { Portal } from "components/Portal"
import ReportWebCheck from "components/report/WebCheck"
import RandomData from "components/RandomData"
import LayoutWithMenu from "components/LayoutWithMenu"
import DnsTimeline from "components/DnsTimeline"

import loadOtxData, { keyNames as otxKeyNames } from "api/otx"
import supabase from "lib/supabase/main"
import { loadRegistrarLog } from "api/registrarLog"
import { loadHuntReportLog } from "api/huntReportLog"
import { loadDnsIntelLog } from "api/dnsIntel"
import {
    registrarKeyNames, registrarRenderPath,
    renderHuntReportPath, ScreenshotTab, HistoryTab
} from "components/DomainExpandable"

const { Title } = Typography;
const DEFAULT_TITLE_LEVEL = 2;

async function loadDomainVariants({ domain }) {
    const { data, error } = await supabase.from("threat_domain_variant")
        .select("variant")
        .eq("domain", domain);
    return { data, error }
}

function renderDomainInfo(data, loading) {
    return (
        <RandomData
            skipRootTabs
            keyNames={registrarKeyNames}
            renderPath={registrarRenderPath}
            loading={loading}
            data={data}
        />
    )
}

function renderSubdomain(row, inx) {
    return (<p key={inx}>{row.variant}</p>)
};

const menu = [
    {
        key: "domain",
        label: "Domain",
        children: [
            {
                key: "registrar",
                label: "Registrar",
            },
            {
                key: "registrant",
                label: "Registrant",
            },
            {
                key: "customer",
                label: "Customer",
            },
            {
                key: "reseller",
                label: "Reseller",
            },
            {
                key: "variants",
                label: "Variants",
            },
            {
                key: "misc",
                label: "Misc",
            },

        ]
    },
    {
        key: "timeline",
        label: "Timeline",
    },
    {
        key: "top-victims",
        label: "Top victims",
    },
    {
        key: "top-countries",
        label: "Top countries",
    },
    {
        key: "dns-log",
        label: "DNS log",
    },
    {
        key: "tarpit-log",
        label: "Tarpit log",
    },
    {
        key: "dns-history",
        label: "DNS intel",
    },
    {
        key: "intelligence",
        label: "Intelligence",
    },
    {
        key: "ioc",
        label: "IOC",
    },
    {
        key: "threats",
        label: "Threats",
    },
    {
        key: "scan",
        label: "Scan",
    },
    {
        key: "screenshot",
        label: "Screenshot",
    },
    {
        key: "history",
        label: "History",
    },
];

const tabsConfig = {
    registrar: {
        title: "Registrar info",
        render: ({ data, loading }) => renderDomainInfo(data, loading),
        load: async ({ domain, toggleLoading, setData }) => {
            toggleLoading("registrar", true);
            toggleLoading("registrant", true);
            toggleLoading("customer", true);
            toggleLoading("reseller", true);
            toggleLoading("misc", true);
            const { data } = await loadRegistrarLog({ domain })
            const registrarData = data[0];
            if (registrarData) {
                const registrar = { ...registrarData };
                const registrant = registrarData.registrant;
                const customer = registrarData.customer;
                const reseller = registrarData.reseller;
                const misc = registrarData.misc;
                delete registrar.registrant;
                delete registrar.customer;
                delete registrar.id;
                delete registrar.gen_creation_date;
                delete registrar.reseller;
                delete registrar.misc;
                delete registrar.indexed;
                delete registrar.source;
                setData(prev => ({
                    ...prev,
                    registrar,
                    registrant,
                    customer,
                    reseller,
                    misc
                }))
            }

            toggleLoading("registrar", false);
            toggleLoading("registrant", false);
            toggleLoading("customer", false);
            toggleLoading("reseller", false);
            toggleLoading("misc", false);
        }
    },
    registrant: {
        title: "Registrant",
        render: ({ data, loading }) => renderDomainInfo(data, loading)
    },
    customer: {
        title: "Customer",
        render: ({ data, loading }) => renderDomainInfo(data, loading)
    },
    reseller: {
        title: "Reseller",
        render: ({ data, loading }) => renderDomainInfo(data, loading)
    },
    misc: {
        title: "Misc",
        render: ({ data, loading }) => renderDomainInfo(data, loading)
    },
    timeline: {
        title: "Timeline",
        render: ({ domain }) => {
            return (
                <DnsTimeline domain={domain} />
            )
        }
    },
    scan: {
        title: "Scan",
        render: ({ domain }) => <ReportWebCheck domain={domain} titleLevel={4} />
    },
    ioc: {
        title: "IOC",
        render: ({ domain }) => {
            return (
                <ThreatFoxIocTable
                    initialParams={{ domain }}
                    showToolbar={false}
                    allowMore={false} />
            )
        }
    },
    threats: {
        title: "Threats",
        load: async ({ domain, setData, toggleLoading }) => {
            toggleLoading("threats", true);
            const { data } = await loadHuntReportLog({ domain });
            setData(prev => ({ ...prev, threats: data[0] }))
            toggleLoading("threats", false);
        },
        render: ({ domain, data, loading }) => {
            return (
                <RandomData
                    skipRootTabs
                    data={data}
                    renderPath={renderHuntReportPath}
                    loading={loading} />
            )
        }
    },
    variants: {
        title: "Domain variants",
        load: async ({ domain, setData, toggleLoading }) => {
            toggleLoading("variants", true);
            const { data } = await loadDomainVariants({ domain });
            setData(prev => ({ ...prev, variants: data }))
            toggleLoading("variants", false);
        },
        render: ({ data = [], loading }) => {
            return (
                <Spin spinning={loading}>
                    <Descriptions bordered className="bg-page">
                        <Descriptions.Item label="Domain variants">
                            <ColumnList
                                data={data}
                                renderItem={renderSubdomain}
                                columns={4}
                                previewSize={0} />
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            )
        }
    },
    "top-victims": {
        render: ({ domain }) => {
            const params = {
                domainId: domain,
                limit: 10,
                pageSize: 10,
                withCompany: true
            };
            return (
                <TopVictims
                    titleLevel={DEFAULT_TITLE_LEVEL}
                    params={params}
                    bordered />
            )
        }
    },
    "top-countries": {
        render: ({ domain }) => {
            const params = { domain };
            return (
                <TopThreatDomainCountries
                    titleLevel={DEFAULT_TITLE_LEVEL}
                    params={params}
                    bordered />
            )
        }
    },
    "dns-log": {
        title: "DNS log",
        render: ({ domain, options: { onLoadMoreDns } }) => {
            return (
                <DnsLogTable
                    initialParams={{ domainId: domain, pageSize: 10 }}
                    ipLink
                    excludeQueryOptions={["questionDomain"]}
                    onLoadMore={onLoadMoreDns} />
            )
        }
    },
    "tarpit-log": {
        title: "Tarpit log",
        render: ({ domain, options: { onLoadMoreTarpit } }) => {
            const params = { domainId: domain, pageSize: 10 };
            return (
                <TarpitLog
                    ipLink
                    initialParams={params}
                    onLoadMore={onLoadMoreTarpit} />
            )
        }
    },
    "dns-history": {
        title: "DNS intel",
        render: ({ data = [], loading }) => {
            return (
                <RandomData data={data} loading={loading} skipRootTabs />
            )
        },
        load: async ({ domain, setData, toggleLoading }) => {
            toggleLoading("dns-history", true);
            const { data } = await loadDnsIntelLog({ domain })
            setData(prev => ({ ...prev, "dns-history": data }))
            toggleLoading("dns-history", false);
        }
    },
    "intelligence": {
        title: "Intelligence",
        render: ({ domain, data, loading }) => {
            return (
                <RandomData
                    data={data}
                    loading={loading}
                    keyNames={otxKeyNames} />
            )
        },
        load: async ({ domain, setData, toggleLoading }) => {
            toggleLoading("intelligence", true);
            const data = await loadOtxData(domain);
            setData(prev => ({ ...prev, intelligence: data }))
            toggleLoading("intelligence", false);
        }
    },
    screenshot: {
        title: "Screenshot",
        render: ({ domain }) => {
            return (
                <ScreenshotTab domain={domain} active />
            )
        }
    },
    history: {
        title: "History",
        render: ({ domain }) => {
            return (
                <HistoryTab domain={domain} active />
            )
        }
    }
}

function Domain() {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const domain = pathname.split("/").pop();

    const onLoadMoreDns = useCallback(
        () => {
            navigate(`/logs/dns?domainId=${domain}`)
        },
        [domain, navigate]
    );

    const onLoadMoreTarpit = useCallback(
        () => {
            navigate(`/logs/tarpit?domainId=${domain}`)
        },
        [domain, navigate]
    );

    return (
        <Page className="page-domain">
            <Portal host="header">
                <div className="header-single-row">
                    <Title level={1}>{domain}</Title>
                    <DomainByDate
                        showToolbar={false}
                        title={null}
                        params={{ domainId: domain }}
                        chartType="bar" />
                </div>
            </Portal>

            <LayoutWithMenu
                menu={menu}
                defaultSelectedKeys={["registrar", "timeline"]}
                defaultOpenKeys={["domain"]}
                pages={tabsConfig}
                pageLoadOptions={{ domain }}
                pageRenderOptions={{ domain, options: { onLoadMoreDns, onLoadMoreTarpit } }}
                layoutProps={{ style: { gap: "1rem" } }}
                siderProps={{ width: "10rem" }}
                contentProps={{ style: { paddingTop: "0.1rem" } }} />
        </Page>
    )
}

export default Domain