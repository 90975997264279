import { useCallback, useEffect, useState } from "react"
import { Spin, Typography } from "antd"

import Page from "components/Page"

import RegistrationsByDate from "components/report/registrarLog/RegistrationsByDate"
import DomainsByDate from "components/report/DomainsByDate"
import TopDomains from "components/report/TopDomains"
import TopVictims from "components/report/TopVictims"
import DnsByDate from "components/report/DnsByDate"
import TarpitByDate from "components/report/TarpitByDate"
import NewIpsByDate from "components/report/NewIpsByDate"
import TopCountries from "components/report/TopCountries"
import LastActiveIpsMap from "components/report/LastActiveIpsMap"

import supabase from "lib/supabase/main"
import formatNumber from "lib/format/number"
import useHtmlClass from "hooks/useHtmlClass"
import { Portal } from "components/Portal"
import TopThreats from "components/report/TopThreats"

async function loadGeneralStats() {
    const { data } = await supabase.from("general_stats").select();
    return data[0] || null;
}

function Dashboard() {

    useHtmlClass("page-dashboard");
    const [statsLoading, setStatsLoading] = useState(true);
    const [stats, setStats] = useState(null);

    const loadStats = useCallback(
        () => {
            loadGeneralStats().then(data => {
                setStats(data);
                setStatsLoading(false);
            });
        },
        []
    );

    useEffect(
        () => {
            loadStats();
            const itv = setInterval(loadStats, 60_000);
            return () => {
                clearInterval(itv);
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Page className="page-dashboard">

            <Portal host="header">
                <Typography.Title level={3}>Last hour activity</Typography.Title>
                <LastActiveIpsMap height="500px" />
            </Portal>

            <div className="page-dashboard-reports-row single">
                <div className="dashboard-general-stats">
                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item flippable">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.ip_count) : <>&nbsp;</>}</h4>
                                <p>Victim IPs registered</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{stats ? formatNumber(stats.new_ip_count) : <>&nbsp;</>}</h4>
                                <p>New IPs per day</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item flippable">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.threat_domain_count) : <>&nbsp;</>}</h4>
                                <p>Threat domains tracked</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{stats ? formatNumber(stats.threat_count) : <>&nbsp;</>}</h4>
                                <p>Threats tracked</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item flippable">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.registered_domain_count) : <>&nbsp;</>}</h4>
                                <p>Domain registrations collected</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{stats ? formatNumber(stats.active_registered_domain_count) : <>&nbsp;</>}</h4>
                                <p>Active domains collected</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item flippable">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.dns_log_count) : <>&nbsp;</>}</h4>
                                <p>DNS requests per day</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{stats ? formatNumber(parseInt(stats.dns_log_count / 86400)) : <>&nbsp;</>}</h4>
                                <p>DNS requests per second</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item flippable">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.tarpit_log_count) : <>&nbsp;</>}</h4>
                                <p>Payload requests per day</p>
                            </div>
                            <div className="stats-card-back">
                                <h4>{stats ? formatNumber(parseInt(stats.tarpit_log_count / 86400)) : <>&nbsp;</>}</h4>
                                <p>Payload requests per second</p>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>

            <div className="page-dashboard-reports-row double w-divider">
                <DnsByDate titleLevel={2} />
                <TarpitByDate titleLevel={2} />
            </div>

            <div className="page-dashboard-reports-row gradient-border double">
                <TopDomains />
                <TopVictims />
            </div>

            <div className="page-dashboard-reports-row double w-divider">
                <RegistrationsByDate />
                <DomainsByDate />
            </div>

            <div className="page-dashboard-reports-row gradient-border double">
                <NewIpsByDate />

            </div>

            <div className="page-dashboard-reports-row double w-divider">
                <TopCountries />
                <TopThreats />
            </div>
        </Page>
    )
}

export default Dashboard