import { useMemo } from 'react';
import { Timeline, Spin } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CountryName from './CountryName';

import useQuery from 'hooks/useQuery';
import supabase from 'lib/supabase/main';
import { interpretDNSRecord } from 'lib/dnsAnswer';
import IpLink from './link/Ip';
import renderGMT from 'lib/renderGMT';

function renderHistoryRecord(record) {
    const parts = [
        <p key="action">
            Domain Registration
        </p>
    ];
    record.registrar && (
        parts.push(
            <p key="registrar">
                Registrar: {record.registrar}
            </p>
        )
    );

    if (record.registrant_email) {
        parts.push(
            <p key="email">
                Registrant email: <Link to={`/registry/domains?query=${record.registrant_email}`} children={record.registrant_email} />
            </p>
        );
    }

    if (record.country) {
        parts.push(
            <CountryName country={record.country} />
        );
    }

    if (record.registration_ip) {
        parts.push(
            <p key="ip">
                Registration IP: <IpLink ip={record.registration_ip} />
            </p>
        );
    }
    return parts;
}

function renderDnsRecord(record) {
    const text = interpretDNSRecord(record.record_type, record.answer);
    const parts = text.split("\n").map((line, index) => (<p key={index}>{line}</p>));
    return parts
}

async function loadDomainInfo(domain) {
    const { data } = await supabase.from("domain")
        .select("*")
        .eq("domain", domain);
    return data[0];
}

async function loadHistory(domain) {
    const { data } = await supabase.from("domain_archive")
        .select("*")
        .eq("domain", domain)
        .order("creation_date", { ascending: true });
    return data;
}

async function loadDnsIntel(domain) {
    const { data } = await supabase.from("dnsintel_log")
        .select("*")
        .eq("domain", domain)
        .order("first_seen", { ascending: true });
    return data;
}

async function loadTimeline({ domain }) {
    const info = await loadDomainInfo(domain);
    const history = await loadHistory(domain);
    const dnsintel = await loadDnsIntel(domain);
    const data = [];
    history.push(info);

    for (const record of history) {
        const date = moment(record.creation_date).format("YYYY-MM-DD");
        let dateEntry = data.find(entry => entry.key === date);
        if (!dateEntry) {
            dateEntry = {
                key: date,
                date: moment(record.creation_date).toISOString(),
                children: []
            };
            data.push(dateEntry);
        }
        dateEntry.children.push({
            type: "history",
            time: moment(record.creation_date).format("HH:mm:ss"),
            content: renderHistoryRecord(record),
            data: record
        });
    }

    for (const record of dnsintel) {
        const date = moment(record.first_seen).format("YYYY-MM-DD");
        let dateEntry = data.find(entry => entry.key === date);
        if (!dateEntry) {
            dateEntry = {
                key: date,
                date: moment(record.first_seen).toISOString(),
                children: []
            };
            data.push(dateEntry);
        }
        dateEntry.children.push({
            type: "dnsintel",
            time: moment(record.first_seen).format("HH:mm:ss"),
            content: renderDnsRecord(record),
            data: record
        });
    }

    data.sort((a, b) => a.key.localeCompare(b.key));
    for (const dateEntry of data) {
        dateEntry.children.sort((a, b) => a.time.localeCompare(b.time));
    }

    return { data };
}

function DnsTimeline({ domain }) {

    const { data, isLoading } = useQuery(
        loadTimeline,
        [domain],
        {
            params: {
                domain
            }
        },
        [domain]
    );

    const items = useMemo(
        () => {
            if (!data) {
                return [];
            }
            const items = [];
            for (const dateEntry of data) {
                const subitems = [];
                for (const child of dateEntry.children) {
                    subitems.push({
                        children: child.content,
                    });
                }
                if (subitems.length === 0) {
                    continue;
                }
                if (subitems.length === 1) {
                    subitems[0].label = renderGMT(dateEntry.date, "MMM D, YYYY", null);
                    items.push(subitems[0]);
                    continue;
                }
                items.push({
                    label: renderGMT(dateEntry.date, "MMM D, YYYY", null),
                    children: (
                        <Timeline
                            items={subitems} />
                    )
                });
            }
            return items;
        },
        [data]
    );

    return (
        <Spin spinning={isLoading}>
            <Timeline
                className="dns-timeline"
                mode='left'
                items={items} />
        </Spin>
    )
}

export default DnsTimeline;