import { Badge, Menu, Flex } from "antd"
import { useContext, useEffect, useMemo, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
// import { ReactComponent as Logo } from "svg/logo.svg"
import { useBreakpoint } from "use-breakpoint"
import {
    AuditOutlined, BarsOutlined,
    AimOutlined, DashboardOutlined,
    ReconciliationOutlined,
    MoreOutlined, UserOutlined
} from "@ant-design/icons"

import { PortalHost } from "./Portal"
import { NotificationsIcon } from "./dialog/Notifications"

import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import supabase from "lib/supabase/main"
import AppContext from "context/AppContext"
import useKeys from "hooks/useKeys"
import { breakpoints } from "theme/config"


function Header() {
    const { breakpoint } = useBreakpoint(breakpoints);
    const [showMenu, setShowMenu] = useState(false);
    const app = useContext(AppContext);
    const location = useLocation();
    const { userProfile, userSettings, isAdmin, userAccess,
        isApproved, isRegistrar, isCompany, loggedInMfa,
        watchlistNotificationsCount = 0,
        expiredDomainsNotificationsCount = 0 } = useKeys(
            ["userProfile", "userSettings", "isAdmin", "userAccess",
                "isApproved", "isRegistrar", "isCompany", "loggedInMfa",
                "watchlistNotificationsCount", "expiredDomainsNotificationsCount"], app);

    const huntViewAccess = userAccess?.hunt && userAccess.hunt?.view;
    const registryViewAccess = userAccess?.registry && userAccess.registry?.view;
    const reportsViewAccess = userAccess?.report && userAccess.report?.view;
    const logsViewAccess = userAccess?.logs && userAccess.logs?.view;

    const huntReportCount = (userSettings?.registrar_hunt_counter || 0);
    const huntCount = huntReportCount + expiredDomainsNotificationsCount;

    const path = location.pathname;

    const currentCompanyId = useMemo(
        () => {
            if (!path || typeof path !== "string") {
                return null;
            }
            const matches = [...(path.match(/company\/([^/?]+)/) || [])]
            return matches?.[1] || null;
        },
        [path]
    );

    useEffect(
        () => setShowMenu(false),
        [path]
    );

    useEffect(
        () => {
            document.documentElement.classList[showMenu ? "add" : "remove"]("no-scroll");
        },
        [showMenu]
    );

    useEffect(
        () => {
            if (breakpoint !== "mobile") {
                setShowMenu(false);
            }
        },
        [breakpoint]
    );

    const onSignout = useSwallowEventCallback(
        async () => {
            await supabase.auth.signOut();
            app.set("loggedIn", false);
        },
        []
    );

    const onMoreClick = useSwallowEventCallback(
        () => setShowMenu(prev => !prev),
        []
    );

    const items = useMemo(
        () => {

            if (!loggedInMfa) {
                return [];
            }

            if (!isApproved) {
                return [];
            }

            if (isRegistrar) {
                return [
                    {
                        key: "/",
                        label: <NavLink to="/" children="Hunt report queue" />,
                    },
                    {
                        key: "/takedown-requests",
                        label: <NavLink to="/takedown-requests" children="Takedown requests" />,
                    },
                    {
                        key: "/expiring",
                        label: (
                            <Flex justify="space-between" align="center">
                                <NavLink to="/expiring" children="Expiring domains" />
                                <Badge count={expiredDomainsNotificationsCount}
                                    className="header-sub-badge" />
                            </Flex>
                        ),
                    },
                ]
            }

            const huntMenu = {
                key: "/hunt",
                icon: <AimOutlined />,
                className: "header-nav-submenu",
                label: (
                    <Badge
                        className="header-top-badge"
                        count={huntCount}>
                        Hunt
                    </Badge>
                ),
                children: [
                    {
                        key: "/hunt/reports",
                        label: <NavLink to="/hunt/reports" children="Reports" />,
                    },
                    {
                        key: "/hunt/preview",
                        label: (
                            <Flex justify="space-between" align="center">
                                <NavLink to="/hunt/preview" children="Automated Hunt Preview" />
                                <Badge count={huntReportCount}
                                    className="header-sub-badge" />
                            </Flex>
                            /*<Badge
                                count={huntReportCount}
                                className="header-sub-badge">
                                <NavLink to="/hunt/preview" children="Automated Hunt Preview" />
                            </Badge>*/
                        )
                    },
                    {
                        key: "/hunt/queue",
                        label: <NavLink to="/hunt/queue" children="Queue" />,
                    },
                    {
                        key: "/hunt/related",
                        label: <NavLink to="/hunt/related" children="Related domains" />,
                    },
                    {
                        key: "/hunt/expiring",
                        label: (
                            <Flex justify="space-between" align="center">
                                <NavLink to="/hunt/expiring" children="Expiring domains" />
                                <Badge count={expiredDomainsNotificationsCount}
                                    className="header-sub-badge" />
                            </Flex>
                            /*<Badge
                                count={expiredDomainsNotificationsCount}
                                className="header-sub-badge">
                                <NavLink to="/hunt/expiring" children="Expiring domains" />
                            </Badge>*/
                        ),
                    },
                    {
                        key: "/hunt/dnsintel",
                        label: <NavLink to="/hunt/dnsintel" children="DNS intel hunt" />
                    },
                    {
                        key: "/hunt/otx",
                        label: <NavLink to="/hunt/intelligence" children="Mythic Intelligence" />
                    },
                    {
                        key: "/hunt/ioc",
                        label: <NavLink to="/hunt/ioc" children="IOC Search" />
                    },
                    {
                        key: "/hunt/scan",
                        label: <NavLink to="/hunt/scan" children="Mythic Scan" />
                    },
                ]
            };

            const registryMenu = {
                key: "/registry",
                label: "Registry",
                icon: <AuditOutlined />,
                className: "header-nav-submenu",
                children: [
                    {
                        key: "/registry/domains",
                        label: <NavLink to="/registry/domains" children="Domains" />
                    },
                    {
                        key: "/registry/entities",
                        label: <NavLink to="/registry/entities" children="Entity search" />
                    },
                    {
                        key: "/registry/threats",
                        label: <NavLink to="/registry/threats" children="Threats" />,
                    },
                    {
                        key: "/registry/dns-intel",
                        label: <NavLink to="/registry/dns-intel" children="DNS intel" />
                    },
                    (!isAdmin && !isCompany) ?
                        {
                            key: "/hunt/reports",
                            label: <NavLink to="/hunt/reports" children="Hunt reports" />
                        } :
                        null
                ]
            };

            const reportsMenu = {
                key: "/reports",
                label: "Reports",
                icon: <DashboardOutlined />,
                children: [
                    {
                        key: "/reports/countries",
                        label: <NavLink to="/reports/countries" children="Countries" />
                    },
                    {
                        key: "/reports/companies",
                        label: <NavLink to="/reports/companies" children="Companies" />
                    },
                    {
                        key: "/reports/threats",
                        label: <NavLink to="/reports/threats" children="Threats" />
                    }
                ]
            };

            const logsMenu = {
                key: "/logs",
                label: "Logs",
                className: "header-nav-submenu",
                icon: <BarsOutlined />,
                children: [
                    {
                        key: "/logs/dns",
                        label: <NavLink to="/logs/dns" children="Dns log" />
                    },
                    {
                        key: "/logs/tarpit",
                        label: <NavLink to="/logs/tarpit" children="Tarpit log" />
                    },
                    {
                        key: "/logs/tarpit-dns",
                        label: <NavLink to="/logs/tarpit-dns" children="Tarpit + DNS" />
                    },
                ]
            };

            if (isCompany) {

                return [
                    registryViewAccess ? registryMenu : null,
                    huntViewAccess ? huntMenu : null,
                    logsViewAccess ? logsMenu : null,
                    reportsViewAccess ? reportsMenu : null
                ].filter(m => !!m);
            }

            return [
                registryMenu,
                (isAdmin || huntViewAccess) ? huntMenu : null,
                logsMenu,
                reportsMenu
            ]
        },
        [isAdmin, huntReportCount, isRegistrar,
            isApproved, loggedInMfa, isCompany,
            huntViewAccess, registryViewAccess, logsViewAccess, reportsViewAccess,
            huntCount, expiredDomainsNotificationsCount]
    );

    const rightItems = useMemo(
        () => {

            if (!loggedInMfa) {
                return [];
            }

            if ((isCompany || isRegistrar) && isApproved) {

                let currentCompanyMenu = null;
                let otherCompaniesMenu = null;

                if (isCompany && userProfile) {
                    let current = null;
                    if (currentCompanyId) {
                        current = userProfile.company.find(c => c.id === currentCompanyId);
                        // const inx = moreMenu.findIndex(m => m.key === currentCompanyId);
                        // moreMenu.unshift(...moreMenu.splice(inx, 1));
                    }
                    else {
                        current = userProfile.company[0] || null;
                    }
                    if (current) {
                        const children = userProfile.company
                            .filter(c => c.id !== currentCompanyId)
                            .map(c => ({
                                key: c.id,
                                label: <NavLink to={`/company/${c.id}`} children={c.name} />
                            }));
                        currentCompanyMenu = {
                            key: "current-company",
                            label: current.name,
                            style: {
                                maxWidth: '200px',
                                //display: "block",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }
                        }

                        if (children.length > 0) {
                            otherCompaniesMenu = {
                                key: "other-companies",
                                label: "...",
                                children
                            }
                        }
                    }
                }

                return [
                    currentCompanyMenu,
                    otherCompaniesMenu,
                    {
                        key: "user",
                        label: userProfile?.first_name || "User",
                        icon: <UserOutlined />,
                        children: [
                            {
                                key: "profile",
                                label: <NavLink to="/profile" children="Profile" />
                            },
                            {
                                key: "signout",
                                label: <a href="/#" onClick={onSignout}>Sign out</a>
                            }
                        ]
                    },
                    isCompany ? {
                        key: "notifications",
                        label: <NotificationsIcon />
                    } : null

                ].filter(m => !!m)
            }

            return [
                isAdmin ?
                    {
                        key: "/admin",
                        label: "Administration",
                        icon: <ReconciliationOutlined />,
                        children: [
                            {
                                key: "/admin/users",
                                label: <NavLink to="/admin/users" children="Users" />,
                            },
                            {
                                key: "/admin/domains",
                                label: <NavLink to="/admin/domains" children="Domains" />
                            },
                            {
                                key: "/admin/servers",
                                label: <NavLink to="/admin/servers" children="Servers" />
                            },
                            {
                                key: "/admin/companies",
                                label: <NavLink to="/admin/companies" children="Companies" />
                            },
                            {
                                key: "/admin/health",
                                label: <NavLink to="/admin/health" children="System health" />
                            },
                            {
                                key: "/admin/ip-checker-log",
                                label: <NavLink to="/admin/ip-checker-log" children="Ip Checker log" />
                            },
                        ]
                    } : null,
                {
                    key: "/watchlists",
                    icon: <AimOutlined />,
                    label: <Badge count={watchlistNotificationsCount} className="header-top-badge">
                        <NavLink to="/watchlists" children="Watchlists" />
                    </Badge>,
                },
                {
                    key: "user",
                    label: userProfile?.first_name || "User",
                    icon: <UserOutlined />,
                    children: [
                        {
                            key: "profile",
                            label: <NavLink to="/profile" children="Profile" />
                        },
                        {
                            key: "mfa",
                            label: <NavLink to="/mfa/factors" children="MFA" />
                        },
                        {
                            key: "agent",
                            label: <NavLink to="/profile/agent" children="Agent keys" />
                        },
                        {
                            key: "signout",
                            label: <a href="/#" onClick={onSignout}>Sign out</a>
                        }
                    ]
                },
                {
                    key: "notifications",
                    label: <NotificationsIcon />
                }
            ]
        },
        [onSignout, userProfile, isAdmin, watchlistNotificationsCount,
            isRegistrar, isApproved, loggedInMfa, isCompany, currentCompanyId]
    );

    const selectedKeys = useMemo(
        () => [path],
        [path]
    );

    return (
        <div id="header-container">
            <div id="header">
                {/* <Link to="/" className="header-home-link"><Logo /></Link> */}
                <Link to="/" className="header-home-link">
                    <h1>Vigilocity</h1>
                </Link>
                <div className="header-more">
                    <a href="/#" onClick={onMoreClick}>
                        <MoreOutlined width="3rem" height="3rem" />
                    </a>
                </div>
                <div className={"header-menus " + (showMenu ? "active" : "")}>
                    {isApproved &&
                        <Menu
                            // openKeys={["/hunt"]}
                            selectedKeys={selectedKeys}
                            mode={breakpoint !== "mobile" ? "horizontal" : "vertical"}
                            items={items}
                            className="header-nav-menu"
                            disabledOverflow />}
                    <Menu
                        style={{ textAlign: "right" }}
                        overflowedIndicator={false}
                        mode={breakpoint !== "mobile" ? "horizontal" : "vertical"}
                        selectedKeys={selectedKeys}
                        items={rightItems}
                        className="header-user-menu"
                        disabledOverflow />
                </div>
            </div>
            <div id="header-portal">
                <PortalHost name="header" />
            </div>
        </div>
    )
}

export default Header