import { useMemo, useState, useCallback } from "react";
import { Typography, Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons"

import { Portal } from "components/Portal";
import Page from "components/Page";
import IpLink from "components/link/Ip"

import supabase from "lib/supabase/main";
import ipaddrr from "ipaddr.js";
import useQuery from "hooks/useQuery";
import renderGMT from "lib/renderGMT";

const DEFAULT_PAGE_SIZE = 100;

const columns = [
    {
        dataIndex: "time",
        title: "Time",
        render: v => renderGMT(v)
    },
    {
        dataIndex: "client_ip",
        title: "Client IP",
        render: ip => <IpLink ip={ip} />
    },
    {
        dataIndex: "requested_ip",
        title: "Requested IP",
        render: ip => <IpLink ip={ip} />
    },

    {
        dataIndex: "found",
        title: "Found",
        render: (found) => found ? "Yes" : "No"
    }
]

export async function loadIpCheckerLog(options = {}) {

    const {
        page = 0,
        pageSize = DEFAULT_PAGE_SIZE,
        ip = null,
    } = options;

    const req = supabase.from("ip_checker_log")
        .select("*", { count: "estimated" })
        .order("time", { ascending: false })
        .range(page * pageSize, (page * pageSize) + pageSize - 1);

    if (ip) {
        try {
            ipaddrr.parse(ip);
            req.or(`requested_ip.eq.${ip},client_ip.eq.${ip}`);
        }
        catch (e) {

        }
    }

    const { data, error, count } = await req;

    return {
        data,
        error,
        count,
        hasMore: (page * pageSize + pageSize) < count
    };
}


function IpCheckerLog({ toolbarSize = "large" }) {

    const [page, setPage] = useState(0);
    const [query, setQuery] = useState("");
    const [ip, setIp] = useState(null);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    let isValidIp = true;
    if (query) {
        try {
            ipaddrr.parse(query);
            isValidIp = true;
        }
        catch (e) {
            isValidIp = false;
        }
    }

    const { data, isLoading, count } = useQuery(
        loadIpCheckerLog,
        [page, pageSize, ip],
        {
            params: {
                page, pageSize, ip
            }
        }
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const pagination = useMemo(
        () => {
            return {
                position: ["bottomCenter"],
                total: count,
                defaultPageSize: 50,
                onChange: onPaginationChange,
                hideOnSinglePage: true,
                pageSize
            }
        },
        [pageSize, count, onPaginationChange]
    );

    const onSearchClick = useCallback(
        () => {
            setPage(0);
            setIp(query.trim());
        },
        [setIp, query]
    );

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        []
    );

    return (
        <Page>
            <Portal host="header">
                <Typography.Title level={1}>Ip Checker log</Typography.Title>
                <div className="toolbar">
                    <Input
                        allowClear
                        placeholder="Search by IP"
                        size={toolbarSize}
                        prefix={<SearchOutlined />}
                        onChange={onQueryChange}
                        value={query} />
                    <Button
                        size={toolbarSize}
                        loading={isLoading}
                        disabled={isLoading || !isValidIp}
                        onClick={onSearchClick}
                        children="Search" />
                </div>
            </Portal>
            <Table
                sticky
                size="small"
                bordered
                tableLayout="auto"
                loading={isLoading}
                dataSource={data}
                columns={columns}
                rowKey="id"
                pagination={pagination} />
        </Page>
    )
}

export default IpCheckerLog