import moment from "moment"

export default function renderGMT(
    ts,
    template = "MMM D, HH:mm:ss",
    templateThisYear = "MMM D, YYYY, HH:mm:ss") {

    if (!ts) {
        return "";
    }

    const dt = new Date(ts);
    const year = dt.getFullYear();
    const thisYear = (new Date()).getFullYear();
    const tpl = year === thisYear && templateThisYear ?
        templateThisYear :
        template;
    const tsStr = moment(ts).utc().format(tpl);
    return tsStr;
}