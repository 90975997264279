

import ThreatName from "components/ThreatName";
import formatNumber from "lib/format/number";
import renderGMT from "lib/renderGMT";
import supabase from "lib/supabase/main"


export const columns = [
    {
        dataIndex: "id",
        key: "id",
        title: "ID",
    },
    {
        dataIndex: "name",
        key: "name",
        title: "Name",
        render: (name, row) => {
            if (row.threat_stats?.dns_log_counter || row.threat_stats?.tarpit_log_counter) {
                return (
                    <ThreatName id={row.id} name={name} />
                )
            }
            else {
                return (
                    <ThreatName id={row.id} name={name} withLink={false} />
                )
            }
        }
    },
    {
        dataIndex: "type",
        key: "type",
        title: "Type"
    },
    {
        dataIndex: "threat_stats",
        key: "dns_log_counter",
        title: "DNS requests",
        render: stats => {
            return formatNumber(stats?.dns_log_counter);
        }
    },
    {
        dataIndex: "threat_stats",
        key: "last_dns_log_match",
        title: "Last DNS request",
        render: stats => {
            if (stats?.last_dns_log_match) {
                return renderGMT(stats.last_dns_log_match);
            }
        }
    },
    {
        dataIndex: "threat_stats",
        key: "tarpit_log_counter",
        title: "Data requests",
        render: stats => {
            return formatNumber(stats?.tarpit_log_counter);
        }
    },
    {
        dataIndex: "threat_stats",
        key: "last_tarpit_log_match",
        title: "Last Data request",
        render: stats => {
            if (stats?.last_tarpit_log_match) {
                return renderGMT(stats.last_tarpit_log_match);
            }
        }
    }
];


export async function loadThreat(options) {

    const {
        page = 0,
        pageSize = 20,
        queryBy,
        query,
        traffic = null
    } = options;

    let stats_graph = "threat_stats!inner(*)";
    if (traffic !== "with") {
        stats_graph = "threat_stats(*)";
    }

    const req = supabase.from("threat")
        .select(`id, name, description, notes, ${stats_graph}`, { count: "estimated" })
        .order("id", { ascending: true })
        .range(page * pageSize, (page * pageSize) + pageSize - 1);

    if (traffic === "with") {
        req.gt("threat_stats.total_count", 0);
    }
    else if (traffic === "without") {
        req.is("threat_stats.total_count", null);
    }

    if (queryBy && query) {
        if (queryBy === "name") {
            req.or(`name.ilike.%${query}%, alias.ilike.%${query}%, id.eq.${query}`);
        }
        else if (queryBy === "description") {
            req.ilike("description", `%${query}%`);
        }
    }

    const { data, error, count } = await req;

    return {
        data,
        columns: columns.map(c => c.dataIndex),
        error,
        count
    };

}