import { useState, useCallback, useMemo } from "react";
import { Button, Input, Table, Tooltip, Typography, Dropdown, Flex } from "antd";
import { CheckOutlined, DownloadOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import Page from "components/Page";
import { Portal } from "components/Portal";
import HuntReportFormDialog from "components/hunt/HuntReportForm"

import DomainExpandable from "components/DomainExpandable";

import { data2csv, downloadCsv } from "lib/csv";
import renderGMT from "lib/renderGMT";
import useAppKey from "hooks/useAppKey";
import useQuery from "hooks/useQuery";
import supabase from "lib/supabase/main";
import useDualState from "hooks/useDualState";


const PER_PAGE = 100;


const queryParams = [
    {
        name: "source",
        default: "",
        autoApply: true
    },
    {
        name: "query",
        default: "",
        serialize: v => v ? v.trim() : undefined
    },
    {
        name: "hasIoc",
        default: null,
        autoApply: true,
        serialize: v => v ? "true" : v === false ? "false" : undefined,
        unserialize: v => v === "true" ? true : v === "false" ? false : null
    },
    {
        name: "hasHuntReport",
        default: false,
        autoApply: true,
        serialize: v => v ? "true" : v === false ? "false" : undefined,
        unserialize: v => v === "true" ? true : v === "false" ? false : null
    },
    {
        name: "hasQueue",
        default: false,
        autoApply: true,
        serialize: v => v ? "true" : v === false ? "false" : undefined,
        unserialize: v => v === "true" ? true : v === "false" ? false : null
    },
];


const columns = [
    {
        dataIndex: "domain",
        key: "domain",
        title: "Domain",
        render: domain => (<Link to={`/reports/domain/${domain}`} children={domain} />)
    },
    {
        className: "table-cell-nowrap",
        dataIndex: "registrant_email",
        key: "registrant_email",
        title: "Registrant email",
        render: email => {
            if (!email) {
                return null;
            }
            const sp = new URLSearchParams();
            sp.set("queryBy", "customerEmail");
            sp.set("query", email);
            return (<Link to={`/registry/domains?${sp.toString()}`} children={email} />)
        }
    },
    {
        dataIndex: "has_queue",
        key: "has_queue",
        title: "In queue",
        width: '80px',
        render: v => v ? "Yes" : "No"
    },
    {
        dataIndex: "has_hunt_report",
        key: "has_hunt_report",
        title: "Hunted",
        width: '80px',
        render: v => v ? "Yes" : "No"
    },
    {
        dataIndex: "has_ioc",
        key: "has_ioc",
        title: "With IOC",
        width: '80px',
        render: v => v ? "Yes" : "No"
    },
    {
        dataIndex: "added_at",
        key: "added_at",
        title: "Added at",
        render: v => renderGMT(v)
    },
    {
        dataIndex: "creation_date",
        key: "creation_date",
        title: "Creation date",
        render: v => renderGMT(v)
    },
    {
        dataIndex: "expiration_date",
        key: "expiration_date",
        title: "Expiration date",
        render: v => renderGMT(v)
    }
]



async function loadDnsIntelHunt(options = {}) {

    const params = { limit: PER_PAGE, ...options };
    const { limit } = params;
    const { data: { rows }, error } = await supabase.functions.invoke("query", {
        body: {
            table: "dnsintel_hunt",
            options: params
        },
        method: "POST"
    });

    if (error) {
        console.error(error);
        return { data: [], error: error, hasMore: false };
    }

    return {
        data: rows,
        error,
        hasMore: rows.length === limit
    };
};

function DnsIntelHunt({ toolbarSize = "large" }) {

    const [page, setPage] = useState(0);
    const [queueInitialData, setQueueInitialData] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const isAdmin = useAppKey("isAdmin");

    const {
        query, setQuery,
        source, setSource,
        hasIoc, setHasIoc,
        hasHuntReport, setHasHuntReport,
        hasQueue, setHasQueue,
        all, apply
    } = useDualState({ params: queryParams, mode: "both" });

    const { data, isLoading, refetch, extraData: { hasMore } } = useQuery(
        loadDnsIntelHunt,
        [page, all.query, hasIoc, hasHuntReport, hasQueue, source],
        {
            append: true,
            params: {
                offset: page * PER_PAGE,
                limit: PER_PAGE,
                sourceId: source,
                query: all.query,
                withIoc: true,
                withHuntReport: true,
                withQueue: true,
                hasIoc,
                hasHuntReport,
                hasQueue
            }
        },
        [all.query, hasIoc, hasHuntReport, hasQueue, source]
    );

    const renderExpanded = useCallback(
        (row) => {

            return (
                <div style={{ padding: 10, paddingTop: 0 }}>
                    <DomainExpandable
                        domain={row.domain} />
                </div>
            )
        },
        []
    );

    const expandable = useMemo(
        () => {
            return {
                expandedRowRender: renderExpanded,
                rowExpandable: () => true,
            }
        },
        [renderExpanded]
    );

    const onSearchClick = useCallback(
        () => {
            apply();
        },
        [apply]
    );

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        [setQuery]
    );

    const onCreateReportClick = useCallback(
        (row) => {
            setQueueInitialData({
                domain: row.domain
            });
        },
        []
    );

    const onDownloadClick = useCallback(
        async () => {
            setDownloading(true);
            const { data: domains } = await loadDnsIntelHunt({
                query,
                hasIoc, hasHuntReport, hasQueue,
                sourceId: source,
                withIoc: true,
                withHuntReport: true,
                withQueue: true,
            });
            const columns = Object.keys(domains[0] || {});
            const csv = data2csv(columns, domains);
            downloadCsv(csv, "dnsintel_hunt.csv");
            setDownloading(false);
        },
        [query, hasIoc, hasHuntReport, hasQueue, source]
    );

    const onLoadMoreClick = useCallback(
        () => {
            setPage(prev => prev + 1);
        },
        []
    );

    const onReportFormClose = useCallback(
        () => {
            setQueueInitialData(null);
        },
        []
    );

    const onReportFormSave = useCallback(
        () => {
            setQueueInitialData(null);
            refetch();
        },
        [refetch]
    );

    const onSelectOption = useCallback(
        ({ key }) => {
            const [type, value] = key.split("/");
            setPage(0);
            if (type === "source") {
                setSource(value);
            }
            else if (type === "option") {
                if (value === "hasIos") {
                    setHasIoc(prev => prev === null ? true : null);
                }
                else if (value === "hasHuntReport") {
                    setHasHuntReport(prev => prev === true ? null : true);
                }
                else if (value === "noHuntReport") {
                    setHasHuntReport(prev => prev === false ? null : false);
                }
                else if (value === "hasQueue") {
                    setHasQueue(prev => prev === true ? null : true);
                }
                else if (value === "noQueue") {
                    setHasQueue(prev => prev === false ? null : false);
                }
            }
        },
        [setSource, setHasIoc, setHasHuntReport, setHasQueue, setPage]
    );

    const optionsMenu = useMemo(
        () => {

            const sourceOptions = [
                {
                    key: "source/",
                    label: "All sources",
                    icon: source === "" ? <CheckOutlined /> : null
                },
                {
                    key: "source/endurance",
                    label: "Endurance",
                    icon: source === "endurance" ? <CheckOutlined /> : null
                },
                {
                    key: "source/namesilo",
                    label: "Namesilo",
                    icon: source === "namesilo" ? <CheckOutlined /> : null
                }
            ];

            const huntOptions = [
                {
                    key: "option/hasHuntReport",
                    label: "Hunted",
                    icon: hasHuntReport === true ? <CheckOutlined /> : null
                },
                {
                    key: "option/noHuntReport",
                    label: "Not hunted",
                    icon: hasHuntReport === false ? <CheckOutlined /> : null
                },
            ];

            const queueOptions = [
                {
                    key: "option/hasQueue",
                    label: "In queue",
                    icon: hasQueue === true ? <CheckOutlined /> : null
                },
                {
                    key: "option/noQueue",
                    label: "Not in queue",
                    icon: hasQueue === false ? <CheckOutlined /> : null
                },
            ];

            const options = [
                {
                    key: "option/hasIos",
                    label: "Has IOC record",
                    icon: hasIoc === true ? <CheckOutlined /> : null
                },
                {
                    value: null,
                    label: "Hunt report",
                    type: "group",
                    children: huntOptions
                },
                {
                    value: null,
                    label: "Queue",
                    type: "group",
                    children: queueOptions
                },
                {
                    value: null,
                    label: "Source",
                    type: "group",
                    children: sourceOptions
                },
            ];

            return {
                items: options,
                size: toolbarSize,
                multiple: true,
                onClick: onSelectOption,
                selectedKeys: [
                    `source/${source}`,
                    hasIoc === true ? "option/hasIos" : null,
                    hasHuntReport === true ? "option/hasHuntReport" : null,
                    hasQueue === true ? "option/hasQueue" : null,
                    hasHuntReport === false ? "option/noHuntReport" : null,
                    hasQueue === false ? "option/noQueue" : null
                ].filter(Boolean)
            }

        },
        [toolbarSize, source, hasIoc, hasHuntReport, hasQueue, onSelectOption]
    );

    const tableColumns = useMemo(
        () => {

            if (!isAdmin) {
                return [...columns];
            }

            return [
                ...columns,
                {
                    key: "actions",
                    dataIndex: "domain",
                    title: "",
                    className: "table-cell-collapse",
                    render: (domain, row) => {

                        if (row.has_queue || row.has_hunt_report) {
                            return null;
                        }

                        return (
                            <Tooltip title="Submit to hunt queue">
                                <Button
                                    size="small"
                                    icon={<CheckOutlined />}
                                    onClick={() => onCreateReportClick(row)} />
                            </Tooltip>
                        )
                    }
                }
            ]
        },
        [onCreateReportClick, isAdmin]
    );


    return (
        <Page className="page-related-domains">
            <Portal host="header">
                <Typography.Title level={1}>DNS intel hunt</Typography.Title>
                <div className="toolbar">
                    <Dropdown
                        trigger="click"
                        menu={optionsMenu}>
                        <Button
                            size={toolbarSize}
                            children="Options"
                            icon={<DownOutlined />}
                            iconPosition="end" />
                    </Dropdown>
                    <Input
                        allowClear
                        size={toolbarSize}
                        prefix={<SearchOutlined />}
                        onChange={onQueryChange}
                        value={query} />
                    <Button
                        size={toolbarSize}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={onSearchClick}
                        children="Search" />
                    <Tooltip title="Download csv">
                        <Button
                            onClick={onDownloadClick}
                            loading={downloading}
                            disabled={downloading || isLoading}
                            size={toolbarSize}
                            icon={<DownloadOutlined />} />
                    </Tooltip>
                </div>
            </Portal>

            <Table
                sticky
                size="small"
                bordered
                tableLayout="auto"
                loading={isLoading}
                dataSource={data}
                columns={tableColumns}
                rowKey="domain"
                pagination={false}
                expandable={expandable} />
            {hasMore && (
                <Flex justify="center" style={{ marginTop: "1rem" }}>
                    <Button
                        onClick={onLoadMoreClick}
                        loading={isLoading}
                        disabled={isLoading}
                        children="Load more" />
                </Flex>
            )}
            <HuntReportFormDialog
                open={!!queueInitialData}
                initialData={queueInitialData}
                onClose={onReportFormClose}
                onSave={onReportFormSave} />
        </Page>
    )
}

export default DnsIntelHunt;