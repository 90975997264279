//import moment from "moment"
import { Link } from "react-router-dom"
import supabase from "lib/supabase/main"
import renderGMT from "lib/renderGMT"
import { downloadCsv } from "lib/csv"
import { mkConfig, generateCsv } from "export-to-csv";
import ThreatName from "components/ThreatName";


export const columns = [
    {
        dataIndex: "domain",
        key: "domain",
        title: "Domain",
        render: domain => <Link to={`/reports/domain/${domain}`}>{domain}</Link>
    },
    {
        dataIndex: "threat_info",
        key: "threat_name",
        title: "Threat",
        render: (threat_info, row) => {
            return (
                <ThreatName id={row.threat_id} name={threat_info?.name || row.threat?.name} />
            )
        }
    },
    {
        dataIndex: "threat_info",
        key: "threat_type",
        title: "Threat type",
        render: (threat_info, row) => threat_info?.type || row.threat?.type
    },
    {
        dataIndex: "created_at",
        key: "created_at",
        title: "Created at",
        render: v => renderGMT(v)
    },
    {
        dataIndex: "reject_reason",
        key: "reject_reason",
        title: "Reject reason",
    },
];

export async function deleteReport(domain) {
    await supabase.from("hunt_report_queue").delete().eq("domain", domain);
}

export async function loadHuntReportQueue(options = {}) {

    const {
        page = 0,
        pageSize = 20,
        status = null
    } = options;

    const req = supabase.from("hunt_report_queue")
        .select("*, threat(*), registrar(*)", { count: "estimated" })
        .order("created_at", { ascending: false })
        .range(page * pageSize, (page * pageSize) + pageSize - 1);

    if (status) {
        req.eq("status", status);
    }
    else {
        req.is("status", null);
    }

    const { data, error, count } = await req;

    const columnsFiltered = status !== "rejected" ?
        columns.filter(c => c.dataIndex !== "reject_reason") :
        columns;

    return {
        data,
        columns: columnsFiltered.map(c => c.dataIndex),
        error,
        count,
        hasMore: (page * pageSize + pageSize) < count
    };
}

export async function sinkholeReports(rows) {
    return await supabase.from("hunt_report_queue")
        .update({ status: "sinkholed" })
        .in("domain", rows.map(r => r.domain));
}

export async function rejectReports(rows, reason) {
    return await supabase.from("hunt_report_queue")
        .update({ status: "rejected", reject_reason: reason })
        .in("domain", rows.map(r => r.domain));
}

export async function queueReports(rows) {
    return await supabase.from("hunt_report_queue")
        .update({ status: null })
        .in("domain", rows.map(r => r.domain));
}

export async function downloadReports(rows) {

    /*const columns = [
        "Domain",
        "Domain Type",
        "Evidence",
        "Threat",
        "Threat Type",
        "Threat Description",
        "Threat Actor",
        "Registrar",
        "Original Name Servers",
        "Creation Date",
        "Expiration Date",
        "Registrant Name",
        "Registrant Email"
    ];*/
    const csvRows = rows.map(row => {
        const csvRow = {};
        csvRow["Domain"] = row.domain;
        csvRow["Domain Type"] = row.domain_info?.type || "";
        csvRow["Evidence"] = (row.threat_info?.evidence || []).join("\n");
        csvRow["Threat"] = row.threat_info?.name || row.threat?.name || "";
        csvRow["Threat Type"] = row.threat_info?.name || row.threat?.type || "";
        csvRow["Threat Description"] = row.threat_info?.description || row.threat?.description || "";
        csvRow["Threat Actor"] = row.threat_info?.actor || "";
        csvRow["Registrar"] = row.registrar?.name || row.registrar_info?.name || "";
        csvRow["Original Name Servers"] = (row.domain_info?.original_name_servers || []).join("\n");
        csvRow["Creation Date"] = row.domain_info?.creation_date_gmt || "";
        csvRow["Expiration Date"] = row.domain_info?.expiration_date_gmt || "";
        csvRow["Registrant Name"] = row.registrant_info?.name || "";
        csvRow["Registrant Email"] = row.registrant_info?.email || "";
        return csvRow;
    });

    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const csv = generateCsv(csvConfig)(csvRows);
    //const csv = data2csv(columns, csvRows);
    downloadCsv(csv, "hunt_report_queue.csv");
}

